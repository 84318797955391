.delivery-pair-measures {
    display: flex;

    &__image {
        width: 46%;

        &-slider {
            position: relative;
            width: 37.8rem;
            height: 35.4rem;

            img{
                border-radius: 1rem;
            }

            .arrows {
                position: absolute;
                bottom: 1.5rem;
                right: 2.7rem;  
                img{
                    cursor: pointer;
                }
                .disabled{
                    opacity: 0.2!important;
                    cursor: none;
                    pointer-events: none;
                }

                img {
                    &:first-child{
                        transform: rotate(180deg);
                        margin-right: .7rem;
                    }
                    
                }
            }
        }
    }

    &__details {
        width: 54%;

        h2{
            margin-bottom: 1rem;
        }

        > .text {
            margin-bottom: 5rem;
        }
    }

    &__data {
        display: grid;
        margin-bottom: 6.4rem;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;

        li {
            border: 1px solid var(--app-theme-bg-light);
            border-radius: .8rem;
            overflow: hidden;


            width: 24.7rem;

            > label {
                height: 3.8rem;
                font-weight: bold;
                color: var(--app-theme);
                background-color: var(--app-theme-bg-light);
                display: block;
                padding: 0 2rem;
                display: flex;
                align-items: center;
            }

            div {
                padding: 2rem 1.5rem;

                label {                    
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    margin-bottom: 1rem;
                    word-break: break-word;   

                    p{
                        flex: 1;
                    }

                    span {
                        display: inline-block;
                        height: 1.84rem;
                        color: var(--app-theme);
                        border: 1px solid;
                        border-radius: .3rem;
                        font-size: 1rem;
                        padding: 0 .75rem;
                        width: 12rem;
                        text-align: center;
                    }
                }
            }
            
        }// DATA END       
        
        &--sun {
            li {
                color: var(--app-sun-theme); 
            }
        }
    }

    &__frame-selection {
        h4 {
            margin-bottom: 2rem;
        }

        .text {
            margin-bottom: 2rem;
        }

        &-reference {
            height: 3.8rem;
            border-radius: .8rem;
            border: 1px solid var(--app-theme-bg-light);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 1.2rem;
            overflow: hidden;

            input{
                height: 100%;
                flex: 1;
                padding-left: 2rem;
                border: none;                
                outline: none;
            }
    
            label {
                font-size: 1.2rem;
                font-weight: bold;
                color: var(--app-theme);
                background-color: var(--app-theme-bg-light);
                height: 100%;
                width: 14.9rem;
                @extend .flex-center;   
                // margin-right: 2rem;         
            }
    
            span {
                width: 5.7rem;
                height: 100%;
                @extend .flex-center;
                border-left: 1px solid $border-color-light-blue;
                color: var(--app-theme);;
                margin-left: auto;
            }
        }    
    }

}