@import './delivery-total';
@import './delivery-summary';
@import './delivery-navigation';
@import './delivery-prescription';
@import './delivery-summary-section';
@import './delivery-pair';
@import './delivery-pair-details';
@import './delivery-pair-consultation';
@import './delivery-pair-measures';
@import './delivery-vision-test';
@import './delivery-advices';

.delivery-page {
    height: $pageHeight;
    position: relative;
    overflow: hidden;

    &__cobrand {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    &__logo {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }

    &__divider {
        height: 70px;
        margin-left: 5px;
        margin-right: 5px;
        border-left: 1px solid black;
    }
}