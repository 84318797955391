.delivery-total {
    height: 100%;
    // background-image: url('./../../../assets/images/delivery-summary.jpg');
    background-size: cover;
    position: relative;
    @extend .flex-center;

    &__modal {
        background-color: #fff;
        min-width: 60.8rem;
        height: 43.8rem;
        border-radius: 2rem;
        padding: 4rem 3rem;

        // @extend .flex-center;
        display: flex;
        align-items: center;
        flex-flow: column;
        
        > * {
            transition: all .3s ease-in-out;
            opacity: 1;
        }

        &--dim {
            > * {
                opacity: .3;
            }
        }

    }

    &__title {
        margin-top: 3rem;
    }

    &__details {
        min-width: 39rem;
        margin-top: auto;
        
        li {
            display: flex;
            padding: 2rem 0;
            
            &:not(:last-child){
                border-bottom: 1px solid $border-color-light;
            }

            &:last-child {
                label {
                    border: none;
                    font-size: 1.4rem;
                    font-weight: normal;
                    justify-content: flex-start
                };
            }
        }

        label {
            height: 2.3rem;
            min-width: 5.5rem;
            padding: 0 1rem;
            border: 1px solid;
            font-size: 1.2rem;
            font-weight: bold;
            border-radius: .4rem;
            margin-right: 2rem;
            @extend .flex-center;
        }

        p {
            font-size: 1.8rem;
            font-weight: bold;
        }

        span {
            font-size: 1.8rem;
            margin-left: auto;
        }
    }

    &__buttons {
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        button{
            &:first-child{
                margin-right: 1rem;
            }
        }
    }

    &__dim {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(#000, 0);
        pointer-events: none;
        transition: all .5s ease-in-out;

        &--on {
            background: rgba(#000, .8);
        }
    }
}