.education-landing{
    padding: 0 50px;
    box-sizing: border-box;
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    &__instruction {
        text-align: center;
        margin-bottom: 25px;

        h2 {
            margin: 0;
            color: $background-dark;
        }

        p {
            color:$text-color-light;
            font-size: 1.4rem;
            width: 600px;
            margin: 1.5rem 0 3rem;
        }
    }

    &__card-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;       
    }

    &__card {
        width: 31%;
        height:400px;
        box-shadow: 0px 0px 20px 0px #3333339e;
        overflow: hidden;
        border-radius: 8px;
        position: relative;

        &-background {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;

            img {
                width: 100%;
            }

            .info{
                position: absolute;
                bottom: 0;
                left: 0;

                width: 100%;
                background: rgba($background-dark, .5);
                height: 45%;

                display: flex;
                align-items: center;
                justify-content: center;
                flex-flow: column;
                color: #fff;
                backdrop-filter: blur(7px);

                span{
                    filter: invert(1);
                    img {
                        filter: brightness(0);
                    }
                }

                label {
                    font-size: 1.8rem;
                    margin: 15px 0 0;
                }
            }

            &--soon {
                .info {
                    height: 100%;
                    border-radius: .8rem;
                    span {
                        display: none;
                    }
                    label{
                        font-size: 2.5rem;
                        margin: 0 0 20px;
                    }
                    button{
                        background: #fff;
                        border: none;
                        padding: 7px 20px;
                        border-radius: 19px;
                        font-size: 1.5rem;
                        font-weight: bold;
                        color: var(--app-theme);
                        outline: none;
                    }
                }
            }
        }
    }
}