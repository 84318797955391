$theme: #0086d6;
$theme-secondary: rgba($theme, 0.15);

$text-color-main: rgb(47, 59, 74);
$text-color-sub: rgba(90, 102, 117, 0.8);
$text-color-light: rgba(90, 102, 117, 0.6);
$text-color-lighter: rgba(90, 102, 117, 0.2);
$text-color-disabled: #e4e4e4;

$seafoamblue: rgb(95, 190, 180);
$seafoamblue-10: rgba(95, 190, 180, 0.1);
$seafoamblue-20: rgba(95, 190, 180, 0.2);

$cerulean: rgb(0, 134, 214);
$cerulean-10: rgba(0, 134, 214, 0.1);

$duckeggblue: rgb(204, 231, 247);

$mediumpink: rgb(235, 92, 109);
$mediumpink-10: rgba(235, 92, 109, 0.1);

$fadedorange: rgb(242, 150, 85);
$fadedorange-10: rgba(242, 150, 85, 0.1);

$border-color: #cfd3d7;
$border-color-light: rgba(90, 102, 117, 0.2);
$border-color-lighter: rgba(90, 102, 117, 0.1);
$border-color-light-blue: rgba($theme, 0.22);

$background-dark: #303b4a;
$background-darker: #121820;
$background-light: rgb(90, 102, 117);
$background-lighter: #f4f5f6;
$pageHeight: calc(var(--window-height) - 12.4rem);

$cdn: 'https://companion-cdn-qua.azureedge.net/sites/';

// DEV
// $cdn: 'https://companion-cdn-dev.azureedge.net/sites/';

// UAT
// $cdn: 'https://companion-cdn-qua.azureedge.net/sites/';

// PROD
// $cdn: 'https://companion-cdn.azureedge.net/sites/';

:root {
    --app-sun-theme: #F35D1D;
    --app-sun-theme-bg-light: #fadfd2;
    --app-sun-theme-bg-lighter: rgba(250, 223, 210, 0.2);
    --app-sun-theme-shadow: hsl(20, 85%, 95%);
    --app-sun-gradient-bg: linear-gradient(90deg, rgba(240, 169, 84, 1) 0%, rgba(249, 81, 98, 1) 100%);
    --app-sun-disable-menu-bg: rgb(236, 223, 218);

    --app-theme: #0086d6;
    --app-theme-bg-light: rgb(204, 231, 247);
    --app-theme-bg-lighter: rgba(204, 231, 247, 0.2);
    --app-theme-shadow: hsl(202, 85%, 95%);
    --app-disable-menu-bg: rgb(216, 224, 234);
}