.advantage{
    display: flex;
    flex-direction: column;
    

    box-sizing: border-box;
    padding: 3rem 6rem;
    width: 100%;

    &__gradient-text{
        margin-bottom: 2rem;
        margin-top: 0;
        font-size: 13px;
    }

    &__gradient-text, &__lipsum{
        width: fit-content;

        background: linear-gradient(90deg, rgba(240,169,84,1) 0%, rgba(249,81,98,1) 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__gradient-text, &__adv-title{
        font-weight: 600;
    }

    &__container{
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: 1.5rem;

        height: 100%;
    }

    &__disclaimer{
        padding-left:16px;
        font-size: 10px;
        text-align: left;
    }

    &__cont{

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-radius: 20px;
        padding: 3rem 2rem;
        width: 100%;

        background: #FEF5F1;

        text-align: center;
        line-height: 1.75rem;

        box-sizing: border-box;
    }

    &__circle{
        position: relative;

        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin-bottom: 0.5rem;

        &--text{
            font-size: 1.75rem;
            font-weight: 700;

            color: #F35D1D;
            position: absolute;
            z-index: 10;
            top: 32%;
            right: 44%;
            transform: translate(40%, 35%);
        }
        
    }

    &__adv-title{
        margin-bottom: 1.25rem;
        line-height: 2rem;
    }

    &__lipsum{
        justify-self: end;
        margin: 0;
    }

    &__progress-cont{
        border-radius: 100%;
        background: #fff;
        transform: rotate(270deg);
    }

    &__progress-bar{
        stroke-dasharray: 201.06;
    }
}