.material-textinput {
    border-bottom: 1px solid var(--app-theme);
    position: relative;
    color: $theme;
    margin: 2rem 0 2.5rem;

    &__input{
        border: 0;
        outline: aliceblue;
        font-size: 1.2rem;
        font-weight: bold;
        padding: 1.5rem 0;
        width: 100%;
        color: var(--app-theme);


        &:focus{
            &::placeholder {
                color: transparent;
            }
        }
        &:focus + label {
            transform: translateY(-1.5rem);
            color: $text-color-main;
        }

        &::placeholder {
            color: var(--app-theme);
        }
    }

    &--with-value {
        label {
            transform: translateY(-1.5rem);
            color: $text-color-main;
        }
    }

    &__label {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.2rem;
        bottom: 0;
        // margin: auto;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: bold;
        transition: all .1s ease-in-out;
        color: transparent;
        pointer-events: none;
    }

    &__error-message{
        position: absolute;
        right: 0;
        bottom: -18px;
        color: red;
    }
}