.ranking-group-image {
    height: 319px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    overflow-y: auto;

    &__box {
        display: flex;
        flex-direction: column;
        padding: 12px;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 208px;
        
        &.clear {
            border: 1px solid $cerulean-10;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 2.25rem; 
        
            >div:last-child() {
              background: transparent;
              backdrop-filter: unset;
              justify-content: center;
            }
            // background: transparent;
            // backdrop-filter: unset;
            // justify-content: center;
        }
                
    }

    &__box > .box-bg {
        background-size: cover;
        width: 100%;
        position: absolute;
        height: 100%;
        top:0;
        left:0%;
        
        background-repeat: no-repeat;
        background-position: top center;
        border-radius: 0.75rem; 

        &.clear {
            position: relative;
            flex: none;
            width: 100%;
            display: block;
            height: auto;
            max-width: 12rem;
        }

    }

    


    &__box > .label-group {
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.95);
        padding: 16px 16px;
        border-radius: 5px;

        width: 100%;
        text-align: left;

        &.withImage {
            margin-top: auto;
        }
       
    }

    &__box > .label-group > label {
        width: 100%;
        // padding: 8px 0;
        color: white;
        font-size: 1rem;
        align-self: flex-start;
        letter-spacing: 0.5px;

        &.-black {
            color: #2F3B4A;
            width: 80%;
            margin: 0 auto;
            text-align: center;
            // height:5rem;
        }

        &.sublabel {
            width: 100%;
            color: white;
            font-size: 1rem;
            font-weight: 400 ; 
            margin-bottom: 1.5rem;
        }

    }

  

    &__ranking-container {
        display: flex;
        gap: 5px;
        text-align: center;
        border-radius: 3px;
        width: 15rem;

        overflow: hidden;
    }

    &__ranking-input {
        display: none;

    }

    &__ranking {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        color: #ffff;
        align-self: center;
        border: 1px solid #FFFFFF;
        border-radius: 3px;
        height:100%;
        text-align: center;
        height: 25px;
        width: 25px;
        cursor: pointer;
        
        // div {
        //     &:first-child {
        //         border-radius: 3px 0px 0px 3px;
        //        }

        //     &:last-child {
        //         border-radius: 0px 3px 3px 0px;
        //     }
        // } 
        
    }

    &__ranking-fill, &__ranking-selected {
        background-color: #ffff;
        align-items: center;
        height: 100%;
        align-self: center;
        border-radius: 3px;
        height: 25px;
        width: 25px;
        // border-left: 1px solid #FFFFFF;
    }

    &__ranking-selected{
        color: #F35D1D;
        display: flex;
        justify-content: center;
    }

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        &__box > .label-group {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            background-color: rgba(124, 124, 124, 0.3);
        }
    }

    &__minmax {
        display: flex;
        align-items: center;
        color: white;
        font-weight: bold;
        font-size: 1.2rem;
        padding-top: 4px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .choice-box-image-duration {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 200px;
    }
}

@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
    .choice-box-image-duration {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 200px;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .choice-box-image-duration {
        display: flex;
        flex-wrap: wrap;

        &__box > .box-bg {
            background-size: 240px 220px;
        }

        &__box > .label-group {
            padding: 8px;

            > label {
                padding: 4px;
                font-size: 0.8rem;
            }
        }
    }
}
