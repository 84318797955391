.prescription-lens {
  display: flex;
  flex-flow: column;

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    > h2 {
      color: $text-color-sub;;
      span{
        color: $text-color-sub;
        font-size: 1.4rem;

      }
    }
  }

  &__content{
    width: 60vw;

    > p {
      margin-top: 8px;
      font-size: 1.4rem;
      color: $text-color-light;
    }
    
    label {
      font-size: 1.5rem;
      font-weight: bold;
      color: $text-color-sub;
      text-decoration: underline;
    }
    
    &-benefits {
      margin-top: 8px;
      > ul {
        list-style-type: "- ";
        font-size: 1.4rem;
        color: $text-color-light;
      }
      
      > ul > li:last-child {
        list-style-type: none;
      }
      
      > ul > li > ul {
        list-style-type: none;
        margin-left: 20px;
      }
      
      > .benefit {
        padding-bottom: 8px;
        font-size: 1.4rem;
        color: $text-color-light;
      }
    }
    
    &-footer {
      display: flex;
      align-items: flex-end;
      margin-top: 32px;
      width: 75vw;
      > img {
        margin-right: 2.25rem;
        width: 200px;
        height: 100px;
      }
      
      > p {
        font-size: 1.4rem;
        color: $text-color-light;
      }
    }
    
    &-citation {  
      font-size: 1.4rem;
      margin-top: 24px;
    }
    
  }

  &__icons{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 48px;
    margin-top: 16px;
    position: absolute;
    bottom: 5vh;
    justify-content: center;
    &-card{
      display: flex;
      flex-direction: column;
      gap: 6px;
      justify-content: center;

      &-icon{
        height: 70px;
        width: 70px;
        border: 1px solid #F35D1D;
        border-radius: 50%;
        margin:auto;
      }

      &-label{
        text-align: center;
        color: #F35D1D;
        font-size: 8px;
      }
    }
  }
  }
  