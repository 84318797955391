.education-controls{
    width: 27.8rem;
    background-color: $background-dark;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    transition: all .5s ease-in-out;

    &--collapsed{
        width: 0rem;

        .education-controls__control {
            transform: translateX(-100%);
        }
    }

    &__control{
        transition: all .5s ease-in-out;
        padding: 1.7rem 4rem 1.5rem 2rem;        
        width: 28.8rem;

        &:not(:last-child) {
            border-bottom: 1px solid $background-light; 
        }

    
        > label {
            color: #fff;
            font-size: 1.2rem;
            font-weight: bold;
            padding-bottom: 1rem ;
            display: inline-block;

            display: flex;
            align-items: center;
            justify-content: space-between;
        }


        &-lens-slider{
            background-color: $background-light;
            border-radius: 3px;

            > label {
                font-size: 1rem;
                color: #fff;
                height: 3.4rem;
                display: inline-flex;
                width: 100%;
                padding: 0 1rem;
                align-items: center;
                border-bottom: 1px solid $background-dark;
            }

            > div {
                height: 3.4rem;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    color: #fff;
                    padding: 1rem;
                }

                > div{
                    height: 1px;

                    > div:last-child{
                        div{
                            width: 1rem;
                            height: 1rem;
                            background-color: var(--app-theme);
                        }
                    }
                }
            }
        }
    }
}

.temp-no-padding-bottom {
    padding-bottom: 0 !important;
}