.home-start {

    height: 7rem;
    border-radius: 1.5rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    padding: 1.5em 2.5em;
    transition: all 1s ease-in-out;
    overflow: hidden;
    position: relative;
    z-index: 1;

    
    @extend .flex-center;

    &__close {
        position: absolute;
        top: 2rem;
        right: 2.5rem;
        opacity: 0;
        transition: all 1s ease-in-out;
        cursor: pointer;
    }

    &--showage {
        height:22.9rem;

        .home-start__close {
            opacity: 1;
        }
    }
}