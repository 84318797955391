
.slide-view-sun {
    width: 100%;
    border-bottom: 2px solid rgba(90, 102, 117, 0.1);
    transition: transform 1s ease-in-out;
    &__slider {
        display: flex;
        height: max-content;
        min-height: 12rem;

        &--0{
            transition: all 0.7s ease-in-out;
            max-height: 13rem;
        }
        &--1{
            transition: all 0.7s ease-in-out;
            max-height: 30rem;
        }
        &--2{
            transition: all 0.7s ease-in-out;
        }

        &-sun{
            display: flex;
            height: 100%;
            > div {
                flex: 1;
                opacity: 0;
                
                &.active-slide {
                    opacity: 1;
                    overflow-y: auto;
                }
            }
        }

        > div:not(:last-child) {
            flex: 1;
            opacity: 0;
            // transition: opacity 0 ease-in-out;
            
            &.active-slide {
                opacity: 1;
                overflow-y: auto;
            }
        }
        
        > div:last-child {
            flex: 1;
            opacity: 0;
            
            &.active-slide {
                opacity: 1;
                overflow-y: auto;
            }
        }
    }
}