.frame-style-selection {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 7.5rem;
    grid-auto-rows: 7.5rem;
    grid-gap: 1rem;
    flex: 1;
    overflow-y: auto;

    &__item {
        display: flex;
        border: 1px solid var(--app-sun-theme-bg-light);
        border-radius: .5rem;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        color: var(--app-sun-theme);
        cursor: pointer;

        img{
            opacity: .2;
            width: 2.5rem;
            margin-bottom: 1rem;
        }

        &--selected {
            background: var(--app-sun-gradient-bg);
            color: #fff;

            img{
                opacity: .8;
                filter: brightness(0) invert(1);
            }
        }
    }
}