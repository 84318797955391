.item-slider {
    position: relative;
    width: 20%;

    &__object {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.3;
        pointer-events: none;
    }

    &__slider {
        -webkit-appearance: none;
        appearance: none;
        height: var(--app-slider-thumb-height);
        width: 100%;
        background-color: transparent;
    }

    &__slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: var(--app-slider-thumb-width);
        height: var(--app-slider-thumb-height);
        cursor: pointer;
        background-color: transparent;
    }
}
