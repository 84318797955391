.product-demo {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 2;

    &__video {
        width: 100%;    
        height: 100%;        
        // object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;

        &-button{
            background-color: $theme;
            color: white;
            font-size: 2rem;
            height: 5rem;
            width: 50rem;
            position: absolute;
            right: 5rem;
            top: 3rem;
            border-radius: 10px;
            border: none;
            padding: 1.2rem 1.5rem;
            border-radius: .7rem;
            font-weight: bold;
            transition: all .25s ease-in-out;
            min-height: 5rem;
            min-width: max-content;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            outline: none;
            cursor: pointer;

            &-icon{
                height: 3rem;
                width: 3rem;
                img{
                    height: 100%;
                }
            }

            &::after{
                content: 'New!';
                right: -4rem;
                top: -9px;
                position: absolute;
                background-color: $theme;
                color: #fff;
                font-size: 1.5rem;
                border-radius: 5px;
                width: 5rem;
                height: 3rem;
                border: 1px solid #fff;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }


    &__qr-video {
        width: 100%;    
        height: 100%;        
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
    &__drive {
        width: 100%;
        height: 100%;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
    }

    &--slider-image {
        @extend .flex-center;

        img{
            height: 100%;
            position: relative;
            pointer-events: none;
        }
    }

    &__qr-head-vto {
        height: 100%;
        width:45%;
        color:white;
        position: absolute;
        z-index: 20;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;

        &::after{
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: -11rem;
            width: 500px;
            background: rgba(0, 134, 214, 0.4);
            transform: skew(10deg);
            transform-origin:top;
        }
    }

    &__qr-head-vto-icon {
        z-index:50;
        height: 50%;
        width: 50%;
        margin-bottom: 100px;
        img{
            height: 100%;
        }
    }
    @media only screen and (max-width: 768px){
        .product-demo__video-button{
            font-size: 1.5rem;
            width: 43rem;
        }           
    }

    @media only screen and (max-width:1280px){
        &__qr-head-vto {
            &::after{
                width: 400px;
            }
        }
    }
}

#image360{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
        
        div:nth-child(1) {
            .pnlm-load-box {
            z-index: -99;
            opacity: 0;
            }
        }
}