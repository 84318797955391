.language {
    &__modal {
        height: 100%;
        display: flex;
        flex-flow: column;
    }

    &__header{
        display: flex;
        align-items: center;
        padding-bottom: 2rem;
        border-bottom: 1px $border-color-light solid;
        position: relative;

        h3{
            margin-left: 5rem;
        }            
    }

    &__offline {
        position: absolute;
        bottom: 2px;
        font-size: 1.25rem;
        left: 3px;
        color: $text-color-sub;
    }

    &__body{
        display: flex;
        padding-top: 1rem;
        height: calc(100% - 15rem);
        overflow-y: auto;
        ul{
            flex: 1 1;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-auto-rows: 6.25rem;
            gap: 1rem;
            width: 100%;
        }
        &.multiple{
            height: 100%;
            ul{
                grid-template-columns: none;
                grid-template-rows: repeat(3, 1fr);
                padding: 0 3rem;
            }
        }
        
        
    }
    &__footer{
        display: flex;
        margin-top: auto;
        justify-content: flex-end;
    }

    .country-btn{
        text-transform: capitalize;
        width: 100%;
        height: 6.2rem;
        border-radius: 0.44rem;
        color: #2F3B4A;
        justify-content: flex-end;        
        font-size: 1.5rem;
        &.selected{
            background-color: var(--app-theme);
            color: white;
        }
        img{
            width: 2.75rem;
            height: 4.58rem;
            margin-right: 2rem;
        }
    }
}   