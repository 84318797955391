@import './education-landing';
@import './education-controls';
@import './education-content';
@import './education-steps';
@import './education-figure';
@import './education-eyeball';
@import './item-slider';
@import './eyeball-crystalline';

.education {
    height: 100%;
    display: flex;
    flex-flow: column;

    &__header {
        height: 7rem;
        width: 100%;
        border-bottom: 1px solid $border-color-light;
        padding: 2.5rem 4rem;
        position: relative;

        display: flex;
        align-items: center;

        h4 {
            margin: auto;

            &.landing {
                margin-left: 0;
            }
        }

        .button {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 3rem;
        }
    }

    &__main {
        display: flex;
        height: 100%;
    }
}
