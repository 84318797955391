.previous-step {
    height: 5.6rem;
    border-bottom: 1px solid $border-color-lighter; 
    display: flex;
    align-items: center;
    justify-content: space-between; 
    padding: 0 3rem ;
    cursor: pointer;
    
    label {
        display: flex;

        span {
            margin-right: 1rem;
        }
    }
}