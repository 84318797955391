.frame-view {
    display: flex;
    flex-flow: column;
    flex: 1;
    position: relative;
    height: 100%;

    .button-tab {
        margin: 0;
        margin-left: auto;

        img{
            opacity: .5;
        }
    }

    &__frame{
        max-width: 45rem;
        margin: auto;
        transition: opacity .25s ease-in-out;
        height: 25vh;

        &--loading {
            opacity: 0.1;
        }
    }

    .loading-spinner {
        inset: 0;
        margin: auto;
        position: absolute;
        z-index: 1;
        top:-40%;
    }
}