.lens-proposal-builder-card{
    border-radius: 15px;
    border: 2px solid $border-color-light-blue;
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    transition: all .25s ease-in-out;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 70%;
        height: 100%;
        background-color: rgba(#fff,.03);
        transform: translateX(30%) skewX(-18deg);
        pointer-events: none;
    }

    &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 3rem;
        height: 100%;
        background-color: rgba(#fff, .03);
        transform: translateX(-3rem) skewX(-18deg);
        left: 0;
        margin: auto;
        pointer-events: none;
    }

    &:not(.lens-proposal-builder-card--completed):hover{
        border-color: $theme;
        button {
            background: $theme;

            img {
                filter: brightness(0) invert(1);
            }
        }
    }

    h2 {
        display: flex;
        align-items: center;

        img {
            width: 3rem;
            margin-right: 2rem;
        }
    }

    &--completed {
        background-color: $theme;
        box-shadow: 0 6px 13px 0px rgba($color: $theme, $alpha: 0.42);

        h2 {
            color: #fff;

            img {
                filter: brightness(0) invert(1);
            }
        }

    }

    &--with-footer{
        h2 {
            transform: translateY(-2rem);
        }
        .button {
            transform: translateY(-2rem);
        }
    }

    &__info{
        
        span {
            color: #fff;
            display: inline-block;
            padding: .25rem 2.5rem;
            background: #5ebeb3;
            border-radius: 12px;
            font-size: 1.2rem;
            margin-top: 2.5rem;
            position: relative;

            
            &::after{
                content: '';
                width: .5rem;
                height: .5rem;
                background-color: #fff;
                display: inline-block;
                position: absolute;
                left: 1.25rem;
                top: 0;
                bottom: 0;
                margin: auto;
                border-radius: 50%;
            }
        }
    }
    button {
        width: 5rem;
        height: 5rem;    
        @extend .flex-center;    
    }

    &--delivery {
        grid-row: 3 / 4;
        grid-column: 1 / -1;    
    }
}

/* KIDS */
.lens-proposal-builder-card--kids{
    @include kidsBackground();
    
    border: none;
    color: #fff;
    box-shadow: 0px 4px 14px -5px #ddd;

    h2{
        img{
            display: none;
        }
    }

    .button {
        border: none;
        background-color: transparent;
        z-index: 1;
    }

    &:hover {
        .button{
            background-color: transparent !important;
        }
    }

    .impacted {
        background-color: #fff !important;
        z-index: 1;

        @extend .kids-color;
        span{
            @extend .kids-color;
        }
    }

    .lens-proposal-builder-card{
        &__info {
            z-index: 2;
            span{
                background-color: #fff;
                @extend .kids-color;

                &:after {
                    @include kidsBackground();
                }
            }
        }
    }

    &:first-child{
        @include kidsBubble(30rem);
        &::before{
            bottom: 0;
            left: -2rem;
            transform: rotate(260deg);
        }
    }

    &:nth-child(2){
        @include kidsBubble(30rem);
        &::before{
            top: 0;
            right: -3rem;
            transform: rotate(-170deg);
        }
    }

    &:nth-child(3){
        @include kidsBubble(30rem);
        &::before{
            top: -1rem;
            left: 4.5rem;
            transform: rotate(290deg);
        }
    }

    &:nth-child(4){
        @include kidsBubble(30rem);
        &::before{
            bottom: 2rem;
            left: -4rem;
            transform: rotate(165deg);
        }
    }

    &:nth-child(5){
        @include kidsBubble(100vw);
        &::before{
            top: -8rem;
            right: -13rem;
        }
    }
}


.lens-proposal-builder-card{
    &--sun {
        align-items: flex-end;

        h2 {
            flex-flow: column;
            align-items: flex-start;
            width: min-content;
                
            img {
                width: initial;
                height: 3rem;
                margin-bottom: 1rem;
            }
        }
        
        button {
            margin-left: auto;
            filter: brightness(0) invert(1);
        }

        &.lens-proposal-builder-card{
            &--delivery {
                grid-row: 2 / 3;
                flex-flow: row;
                align-items: center;
                border-color: $border-color-light;
                background-color: #fff;

                &:hover {
                    border-color: $border-color-light;
                }

                h2 {
                    flex-flow: row;
                    align-items: center;
                    color: $text-color-main;
                        
                    img {
                        filter: none;
                        height: 4.5rem;
                    }
                }

                button {
                    filter: none;
                    margin-left: 2rem;
                }

                .lens-proposal-builder-card__info {
                    justify-content: center;
                }

                &.incomplete .button {
                    display: none;
                }
            }
        }

        &.lens-proposal-builder-card--completed {
            button {
                filter: none;
            }
        }
    }


    &__info  {
        &--sun {
            height: 100%;
            display: inline-flex;
            align-items: flex-start;
            flex-flow: column;

            span {
                margin-top: auto;
                background-color: #fff;
                transform: translateY(-1.5rem);
                font-weight: bold;
            }
        }
    }
}

.lens-proposal-builder-card--sun.lens-proposal-builder-card--completed{
    &.lens-proposal-builder-card{
        &--prescription {
            border: none;
            background: linear-gradient(226deg, rgba(234,92,106,1) 0%, rgba(237,117,75,1) 100%);
            box-shadow: 0px 10px 20px 0px rgba(209,100,116,.6);    

            span {
                color: #ed734d;
                &::after {
                    background-color: #ed734d;
                }
            }
        }

        &--measures {
            border: none;
            background: linear-gradient(226deg, #e3d251 0%, #f1b139 100%);
            box-shadow: 0px 10px 20px 0px rgba(240,177,57,0.6);    

            span {
                color: #e3d251;
                &::after {
                    background-color: #e3d251;
                }
            }
        }

        &--lens-consultation {
            border: none;
            background: linear-gradient(226deg, #367270 0%, #254646 100%);
            box-shadow: 0px 10px 20px 0px rgba(48,110,108,0.6);

            span {
                color: #367270;
                &::after {
                    background-color: #367270;
                }
            }
        }

        h2 {
            color: #fff;                
            img {
                filter: brightness(0) invert(1);
            }
        }

        .button {
            border: none;
        }
    }
}
