@keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    20% {
      opacity: 0;
    }
  
    40% {
      opacity: 0.3;
    }
  
    60% {
      opacity: 0.5;
    }
  
    80% {
      opacity: 0.9;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes loading {
    0% {
        
        margin-left: 0;
        margin-right: 100%;
    }
  
    50% {
      margin-left: 0%;
      margin-right: 0%;
    }
  
    100% {      
        margin-left: 100%;
        margin-right: 0;
    }
  }

@-webkit-keyframes scale-up-hor-left {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
    }
  }
  @keyframes scale-up-hor-left {
    0% {
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
    }
  }

.progress-overlay{
    width:100%;
    position:absolute;
    z-index:80;
    display:flex;
    margin-top:7rem;
    justify-content:center;
    background: rgba(0, 0, 0, 0.5);
}

.progress-popup{
    height:100%;
    width:100%;
    border-radius:15px;
    margin-right:11rem;
    padding:2rem;

    &__label{
        display:flex;
        flex-direction: column;
        font-size: 1.8rem;
        text-align: center;

        &-text{
            background:var(--app-sun-gradient-bg);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            width: 100%;
            padding:16px;
            font-weight: 800;
        }
    }

    &__progress-container{
        display: flex;        
        width: 25rem;
        height: 1rem;
        background-color:white;
    }
}

.progress-container__bar{
    overflow: hidden;
    width: 80pt;
    height: 6px;
    border-radius: 3pt;
    background-color: var(--app-sun-theme-bg-light);
    margin: 0 auto;
    margin-top: 50px;

    .progress-value{
        -webkit-animation: scale-up-hor-left 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite forwards;
        animation: scale-up-hor-left 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite forwards;
        border-radius: 3px;
        background: var(--app-sun-gradient-bg);
        height: 8px;
        width: 100%;
        &.waiting{
          background: var(--app-sun-theme-bg-light);
          animation: none;
      }
        &.done{
            background-color: white;               
            animation: fadeIn 0.4s ease-out;     
        }
    }
    
}