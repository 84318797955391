.choice-box-image-duration {
    // display: grid;
    // grid-template-columns: 1fr;
    // grid-auto-rows: 280px;
    // grid-gap: 24px;
    // overflow-y: auto;
    // padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bolder;
    margin-top: 2rem;
    height:100%;

      &__box {
        height: 40rem;
        width: 67.2%;
        display: flex;
        flex-direction: column;
        padding: 16px;
        position: relative;
        justify-content: center;
        align-items: center;
    }

    &__box > .box-bg {
        width: 100%;
        position: absolute;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        border-radius: 2.25rem;
    }

    &__box > .label-group {
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.95);
        padding: 0 16px 16px;
        border-radius: 5px;
        margin-top: auto;
        width: 100%;
    }

    &__box > .label-group > label {
        width: 100%;
        padding: 8px 0;
        color: white;
        font-size: 1.4rem;
        font-weight: bold;
    }

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        &__box > .label-group {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            background-color: rgba(124, 124, 124, 0.5);
        }
    }

    &__minmax {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        font-weight: bold;
        font-size: 1.2rem;
        padding-top: 4px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .choice-box-image-duration {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 200px;
    }
}

@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
    .choice-box-image-duration {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 200px;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .choice-box-image-duration {
        display: flex;
        flex-wrap: wrap;

        &__box > .box-bg {
            background-size: 240px 220px;
        }

        &__box > .label-group {
            padding: 8px;

            > label {
                padding: 4px;
                font-size: 0.8rem;
            }
        }
    }
}
