.side-box-bordered {
    height: 100%;
    width: 39rem;
    padding: 2.5rem;
    border-radius: 1rem;
    margin-right: 4rem;
    position: relative;
    z-index: 90;

    @include responsive(EXTRASMALL){
        width: 32rem;
        margin-right: 2rem;
    }

    @include responsive(SMALL){
        width: 35rem;
    }

    @include responsive(MEDIUM){
        width: 45rem;
    }

    @include responsive(LARGE){
        width: 56rem;
    }
    

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: column;

    @extend .box-shadow-light-blue;


    &:before {
        content: '';
        width: .65rem;
        height: 100%;
        background-color: var(--app-theme);
        position:absolute;
        left: -5px;
        top: 0;
        border-top-right-radius: .5rem;
        border-bottom-right-radius: .5rem;
        @extend .box-shadow-light-blue;
        z-index: 2;
    }


    &--plain {
        box-shadow: 0 10px 20px 0 rgba(47,59,74,0.1);
        padding: 0rem;
        &::before {
            display: none;
        }
    }
}