.circle-arrow {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    @extend .flex-center;


    &--left {
        transform: rotate(180deg);
    }

    &--top {
        transform: rotate(270deg);
    }

    &--bottom {
        transform: rotate(90deg);
    }
}

