.measure-modal {
    &__card{
        position: absolute;
        top: 3.5rem;
        right: 3.5rem;
        z-index: 2;
        width: 35rem;
        border-radius: 12px;
        padding: 2rem;
        background-color: #fff;
        overflow-y: scroll;
        min-height: max-content;
        max-height: 75%;
        &::-webkit-scrollbar-thumb {
            border-top-right-radius: 100px !important;
            border-bottom-right-radius:100px !important;
          }
        &::-webkit-scrollbar-track {
            background: transparent;
          }

        &--kids {
            @include kidsBackground();
            color: white !important;
            label{
                color: white !important;
            }
            .input-text{
                background-color: transparent !important;
                color: white;
                &--light{
                    border-color: rgba(255, 255, 255, 0.22) ;
                }
                &:focus{
                    border-color: white;
                }
                span{
                    color: white !important
                }
            }
        }
    }
    

    &__head{
        font-size: 1.25rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3rem;
    }

    &__head > .ivs {
        display: flex;
        justify-content: flex-end;

        > .info {
            font-size: 1rem;
            text-align: right;
            padding-right: 4px;
            font-weight: normal;
            width: 200px;
        }
    }

    &__label {
        font-size: 1.35rem;
        font-weight: bold;
        margin-bottom: 1rem;
        display: block;
        
    }

    &__description {
        font-size: 1.35rem;
        color: $text-color-light;
        line-height: 1.5;
        &--kids{
            color: white;
        }
    }
    
}