.my-companion-footer {
    border-top: 1px solid $border-color-light;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 8rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem;
    background: #fff;

    .button {
        &:first-child {
            margin-right: 1rem;
            margin-left: 4rem;
        }

        &:last-child {
            margin-left: auto;
        }
    }

    .align-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .align-right > .date-sync {
        margin-left: 48px;
        margin-bottom: 4px;
    }

    .align-right > .buttons {
        display: flex;
        align-items: center;
    }

    &__saved {
        color: var(--app-theme);

        height: 100%;
        flex-flow: column;
        @extend .flex-center;

        h2 {
            margin-top: 2rem;
        }
    }

    &__confirm {
        color: $theme;

        height: 100%;
        flex-flow: column;
        text-align: center;
        font-weight: bold;
        @extend .flex-center;

        > p {
            font-size: 16px;
        }

        > div {
            display: flex;
            margin-top: 16px;
        }

        > div > button:first-child {
            margin-right: 16px;
        }
    }
}
