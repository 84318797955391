.lens-proposal-experience {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    // margin: auto;
    z-index: 2;
    // width: 100%;
    height: 20rem;
    background: $background-dark;
    // max-width: 144rem;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 3rem;

    .button {
        margin: 0 1rem;
        position: relative;
        overflow: hidden;


    }

    .icon {
        width: .75rem;
        height: .75rem;
        border: 1px solid #fff;
        border-radius: 50%;
        margin-left: 2rem;
        transform: translateY(.2rem);

        &--active{
            background: var(--app-theme);
            border-color: var(--app-theme);
        }
    }
}