:root{
    --app-arrow-rotate:45deg;
}

.arrow {
    border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: var(--app-theme);
    border-image: initial;
    transition: all .25s ease-in-out;
    display: inline-block;
    
    &--down{
        --app-arrow-rotate:45deg;
    }

    &--up{
        --app-arrow-rotate:225deg;
    }

    &--right {
        --app-arrow-rotate:-45deg;
    }

    &--left {
        --app-arrow-rotate:140deg;
    }

    &--disabled {
        border-color: #b7b7b7;
    }

    transform: rotate(var(--app-arrow-rotate));
}