.navbar {
    display: flex;
    align-items: center;
    padding: 2rem 3.5rem;
    position: relative;

    &__logo {
        width: 4.4rem;
        height: 4.4rem;
        margin-right: 134px;

        img {
            height: 100%;
            width: 100%;
        }
    }

    &__center {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__subtitle {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    &__for {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-right: 10px;
    }

    &__cobrand {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }

    &__title {
        font-family: 'AkhandSoft-Bold', Arial, sans-serif;
        color: $text-color-main;
        width: 100%;
        text-align: center;
        pointer-events: none;
        font-size: 2.5rem;
        font-weight: 700;
    }

    &__navigation {
        @extend .flex-center;

        a:not(:last-child) {
            margin-right: 1.5rem;
        }

        .spacer {
            width: 1px;
            height: 1rem;
            background-color: #eee;
            margin: 0 2.5rem 0rem 0;
        }

        .close {
            position: absolute;
            top: 2rem;
            right: 3.5rem;
        }

        .refresh {
            width: 2.2rem;
            height: 2.2rem;
            opacity: 0.7;
        }

        .tooltip {
            position: relative;
            display: inline-block;
        }

        .tooltip > .tooltiptext {
            visibility: hidden;
            width: 120px;
            background-color: black;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            position: absolute;
            z-index: 1;
            top: 150%;
            left: 50%;
            margin-left: -60px;
            background-color: #0086d6;
            font-size: 12px;
        }

        .tooltip > .tooltiptext::after {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #0086d6 transparent;
        }

        .tooltip:hover > .tooltiptext {
            visibility: visible;
        }

        .refresh-diag{
            position: relative;
        }
    }

    &--discovery {
        z-index: 3;

        .navbar {
            &__title {
                color: #fff;
            }

            &__navigation {
                img {
                    filter: brightness(0) invert(1);
                }

                .spacer {
                    opacity: 0.1;
                }
            }
        }
    }

    .refresh-diag:hover,
    .refresh-diag.active {
        .tooltip-box {
            display: flex;
        }
    }

    &__demo-button{
        img {
            width: 2.5rem;
            opacity: 0.8;
        }
    }
}
