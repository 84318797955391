.card-notification {
    float: right;
    font-size: 12px;
    border-radius: 50%;
    background: var(--app-theme);
    color: #fff;
    width: 30px;
    display: flex;
    justify-content: center;
    padding: 3px;
    border: 3px solid #fff;
    box-shadow: 0 0 5px 7px rgba(0, 134, 214, .1);
    margin-top: -10px;
    margin-left: -45px;
    margin-right: 18px;

    &__side {
        margin-top: -60px;
        margin-left: 0;
        margin-right: -50px;
        position: absolute;
    }

}