.prescription-side {

    &__title{
        display: flex;
        align-items: center;
        color: var(--app-theme);
        font-size: 1.2rem;
        font-weight: bold;
        z-index: 2;


        img{
            margin-right: 1rem;

        }
        &.white{
            color:white;
        }
        .completed{
            filter: invert(37%) sepia(93%) saturate(1009%) hue-rotate(174deg) brightness(90%) contrast(104%);
        }
    }
    &__header{
        display: flex;
        justify-content: space-between;
        width: 100%;
        button{
            img{
                filter: hue-rotate(
                    40deg
                    );
            }
        }
    }

    &__image {
        margin: auto;
        width: 80%;
    }

    &__clear-lens {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        overflow: hidden;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        overflow: hidden;
    }

    &__clear-lens.-sun{
        top: unset;
        bottom: 0;
        height: 92%;
    }

    &__precision {
        position: relative;
        height: 100%;
        width: 100%;
        overflow: hidden;

        img {
            width: calc(100% - 4rem);
            max-width: 30rem;
            position: absolute;
            @extend .position-center-absolute;
            transition: all .75s ease-in-out;

            &:first-child {
                left: calc(50% + 20rem);
                transform: translate(-50%, -50%) scale(.6);
                opacity: 0;
            }
        }

        &--swap {
            img {
                &:first-child {
                    left: calc(50%);
                    transform: translate(-50%, -50%) scale(1);
                    opacity: 1;
                }

                &:last-child{
                    left: calc(50% - 20rem);
                    transform: translate(-50%, -50%) scale(.6);
                    opacity: 0;
                }
            }
        }
    }


    &__buttons {
        display: flex;
        margin-left: auto;
        z-index: 2;

        .button {
            margin-left: 1rem;
        }
    }

    &__import-modal {
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-flow: column;
        padding: 5rem 4.5rem;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto auto 0;

            .button {
                margin: 0 .5rem;
            }
        }
    }
}
