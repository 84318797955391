.frequency {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bolder;
    width: 600px;
    height: 400px;

    &__content{
        // background-image: url("./variluxstandard.png") ;
        // font-size: 1.2em;
        height: 100%;
        width:100%;
        color: #fff;
        // max-width: 82.8rem;
        // background-color: #ddd;
        // border-radius: 0.8rem;
        display: flex;
        flex-direction: column;
        padding: 20px;
        position: relative;
        justify-content: center;
        align-items: center;
   
        &-choices-container{
            display: flex;
            align-items: end;
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        &-choices{
            padding: 1.2rem;
            display: flex;
            gap: 1.2rem;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            height: max-content;
            width: 100%;
            backdrop-filter: blur(20px);
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 5px;

            &-flat {
                padding: 1.2rem;
                display: flex;
                gap: 1.2rem;
                justify-content: space-between;
                align-items: center;
                color: #fff;
                height: 5.1rem;
                width: 100%;
                backdrop-filter: blur(20px);
                background-color: rgba(255, 255, 255, 0.2);
                border-radius: 5px;
            }
        }
    }

    &__content > .box-bg {
        background-size: cover;
        width: 100%;
        position: absolute;
        height: 100%;
        top:0;
        left:0%;
        
        background-repeat: no-repeat;
        background-position: top center;
        border-radius: 2.25rem;
    }

    &__never{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        height: 100%;
        width: 8.5rem;
        border: 1px solid #fff;

        &--selected{
            background-color: #fff;
                p{
                    background: -webkit-linear-gradient(90deg, rgba(240,169,84,1) 0%, rgba(249,81,98,1) 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
        }

    }

    &__choices {
        flex: auto;
        height: 100%;
        border: 1px solid #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        &-1, &-2, &-3, &-5{
            cursor: pointer;
            height: 100%;
            width: 100%;
            display: grid;
            gap:12px;
        }
        &-1, &-3{
            grid-template-columns: repeat(3, 1fr);
        }
        &-2{
            grid-template-columns: repeat(4, 1fr);
        }
        &-5{
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__choice {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-right: 1px solid #fff;
        color: #fff;
        &-2{
            span{
                width: 65%;
            }
        }
        &-1, &-2, &-3, &-5{
            border: 1px solid #fff;
            border-radius: 3px;
            height: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.1rem;
            text-align: center;
            &--selected{
                background-color: #fff;
                span{
                    background: -webkit-linear-gradient(360deg,rgba(240,169,84,1)0%,rgba(249,81,98,1)100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
        &:last-child{
            border-right: 0;
        }
        span{
            text-align: center;
            width: 75%;
            font-weight: bold;
        }
        &--selected{
            background-color: #fff;
            span{
                background:  var(--app-theme);;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        &--skipped{
            background-color: #fff;
            span{
                background: -webkit-linear-gradient(90deg, rgba(240,169,84,1) 0%, rgba(249,81,98,1) 50%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                opacity: 0.6;
            }
        }


    }
}

@media only screen and (max-width: 1024px) {
    .rate-image {
      font-size: 8px;
    
      &__content{
          width: 80%;
      }

    }


  }