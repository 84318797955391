@import './no-rx-footer';

.no-rx-modal {
  img {
    width: 7rem;
  }

  .modal__content {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 3rem 1rem 2rem 5rem;
  }

  h4 {
    color: var(--app-sun-theme);
  }

  p {
    font-size: 1.4rem;
    color: $text-color-light;
  }

  label {
    font-size: 1.2rem;
    font-weight: bold;
    color: $text-color-sub;
  }

  &__text-important {
    background-color: rgba(#5a6675, 0.1);
    border-radius: 0.5rem;
    width: max-content;
    height: 4.9rem;
    padding: 1.2rem 3rem;
  }

  &__text-vision-outside {
    margin-bottom: 1.5rem;
  }

  &__center-content {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > div:first-child {
      flex: 1;
      padding-right: 7rem;
    }
  }

  &__next {
    box-shadow: -1px 0px 3px -2px #d5d5d5;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    cursor:pointer;
  }

  &__info {
    width: 100%;
    margin-top: 4rem;

    label {
      display: block;
      margin-bottom: 3rem;
    }

    &-list {
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      width: 100%;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        text-align: center;

        label {
          margin-top: 1.5rem;
          color: var(--app-sun-theme);
          width: 14rem;
        }
      }
    }

    &-list {
      &--myopia,
      &--hyperopia,
      &--presbyopya,
      &--astigmatism {
        align-items: flex-start;

        li {
          flex-flow: row;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          text-align: left;
          padding-right: 5.5rem;
          flex: 1;

          img {
            width: 4rem;
            margin-right: 1rem;
          }
          label {
            margin: 0;
          }

          p {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
