@import './lens-consultation-side-v2';
@import './lens-recommendation';
@import './lens-animation';
@import './lens-family';
@import './lens-transition-in-animation';
@import './lens-transition-out';
@import './lens-consultation-sun-xp';
@import './frame-view';
@import './frame-style-selection';
@import './frame-selection-step';
@import './protect-selection-step';
@import './multiple-choice';
@import './previous-step';
@import './tint-selection-step';
@import './tint-selection';
@import './advantages';
@import './advantage-item';
@import './lens-consultation-side-sun';
@import './lens-family-sun';


.lens-consultation-v2 {
    display: flex;

    &__content{
        flex:1;
        height: 100%;
        position: relative;
        overflow: hidden;
        position: relative;
    }

    &__slide-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__slide-back {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;
        opacity: 0;
        transition: .25s ease-in-out;

        width: 100%;
        padding: 1rem 0;
        background: #fff;

        &--show {
            pointer-events: initial;
            transition: 1s ease-in;

            opacity: 1;
        }
    }
    
    &__back{
        margin-bottom: 2rem;
    }
}





