.legal-notices{
    height: $pageHeight;
    padding: 0 9rem  0 15rem;
    overflow-y: auto;

    &__section {
        display: flex;
        margin-bottom: 3rem;
    }

    &__bullet {
        color: var(--app-theme);
        font-weight: bold;
        font-size: 1.25rem;
        padding: 3rem 2rem;
    }

    &__content {
        padding-left: 3rem;

        h2{
            padding: 2rem 0;
        }
    
        &-paragraph{
            font-size: 1.5rem;
            color: $text-color-light;
        }
    }
    
}