.protect-selection-step {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    flex-flow: column;

    &__content {
        padding: 3.5rem 3rem;
        flex: 1;
        overflow: auto;

        > label {
            color: var(--app-sun-theme);
        }

        h4 {
            margin-bottom: 3rem;
        }
    }


    &__divider {
        border: 1px solid $border-color-lighter;
        border-bottom: none;
        margin: 2.5rem 0;
    }

    &__saved {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        color: var(--app-sun-theme);
        margin-top: 2rem;

        > div {
            margin-right: 1rem;
        }
    }
}