.need-explorer-sun-experience {
    box-shadow: 0 10px 20px 0 rgba(47,59,74,0.1);
    border-radius: 1.5rem;
    margin: 0 35px;
    overflow-y: auto;

    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-flow: column;
    
    &__back{
        align-self: flex-start;
    }

    &__question {
        text-align: center;
        p{
            color: var(--app-theme);
        }
    }

    &__question-choices {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__question-choice {
        height: 29.6rem;
        width: 100%;
        max-width: 27.8rem;

        background-color: #ddd;
        border-radius: .8rem;

        &:not(:last-child){
            margin-right: 3rem;
        }

        &--selected {
            border: 4px solid var(--app-theme);
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &-previous-question {
            cursor: pointer;
        }


        &-divider{
            display: inline-block;
            width: 1px;
            height: 1.75rem;
            background-color: #eee;
            margin: 0 2rem;
        }
    }

    &__saved {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 2rem;
        &-label{
            display: flex;
            gap: 5px;
            font-size: 1.3rem;
            background: var(--app-sun-gradient-bg);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &__footer-pagination{
        display: flex;
        justify-content: center;
        align-items: center;

        &-page{
            width: .5rem;
            height: .5rem;
            background-color: var(--app-theme-bg-light);
            border-radius: 50%;

            &:not(:first-child) {
                margin-left: .75rem;
            }

            &--current {
                background: var(--app-sun-gradient-bg);
                width: .8rem;
                height: .8rem;
                padding-bottom: 1px;

                & ~ li {
                    background-color: #eee;
                }
            }
        }
    }
}