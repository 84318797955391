.drag-and-drop-sorter {
    &__drag {
        display: flex;

        &-item {
            flex: 1;
            border: 1px solid $theme;
            border-radius: 8px;
            height: 15rem;
            @extend .flex-center;
            font-size: 1.2rem;
            color: $theme;
            user-select: none;
            cursor: pointer;
            position: relative;
            &:not(:last-child) {
                margin-right: 1rem;
            }

            &--with-value {
                border: none;
                background: var(--app-theme-bg-light);
                color: transparent;
                cursor: unset;

                > .image {
                    visibility: hidden;
                }

                > .label {
                    visibility: hidden;
                }
            }

            > .image {
                top: 0;
                left: 0%;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
                cursor: pointer;
                border-radius: 8px;
            }

            > .label {
                position: absolute;
                color: white;
                width: 100%;
                text-align: center;
            }
        }
    }

    &__drop {
        display: flex;
        margin-bottom: 2rem;

        &-item {
            user-select: none;
            flex: 1;
            border: 0.8px solid var(--app-theme);
            border-radius: 8px;
            height: 15rem;
            font-size: 2.5rem;
            font-weight: bold;
            color: var(--app-theme);
            position: relative;

            @extend .flex-center;

            &:not(:last-child) {
                margin-right: 1rem;
            }

            &--remove-card {
                right: 1rem;
                position: absolute;
                top: 1rem;
                font-size: 1.2rem;
                width: 2.1rem;
                height: 2.1rem;
                border: 1px solid #fff;
                border-radius: 0.4rem;
                padding: 0;
                cursor: pointer;

                &::after {
                    content: 'X';
                    font-size: 1rem;
                    color: white;
                }
            }

            &--with-value {
                background-color: var(--app-theme-bg-light);
                color: #fff;
                font-weight: normal;
                font-size: 1.2rem;
                cursor: unset;
                span {
                    position: absolute;
                    top: 1rem;
                    left: 1rem;
                    font-size: 1.2rem;
                    width: 2.1rem;
                    height: 2.1rem;
                    border: 1px solid #fff;
                    border-radius: 0.4rem;
                    @extend .flex-center;
                }
            }

            > .image {
                top: 0;
                left: 0%;
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
                cursor: pointer;
                border-radius: 8px;
            }

            > .label {
                position: absolute;
                color: white;
                text-align: center;
            }
        }
    }
}
