.education-eyeball {
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    margin-top: 32px;

    &.--none {
        background-image: none;
    }

    &.--darkmax {
        background-image: url('../../../assets/images/education/Education_Eye_Dark-Max.svg');
    }

    &.--darkmid {
        background-image: url('../../../assets/images/education/Education_Eye_Dark-Mid.svg');
    }

    &.--darkmin {
        background-image: url('../../../assets/images/education/Education_Eye_Dark-Min.svg');
    }

    &.--lightmax {
        background-image: url('../../../assets/images/education/Education_Eye_Light-Max.svg');
    }

    &.--lightmid {
        background-image: url('../../../assets/images/education/Education_Eye_Light-Mid.svg');
    }

    &.--lightmin {
        background-image: url('../../../assets/images/education/Education_Eye_Light-Min.svg');
    }

    > .education-eyeball__eyeimage {
        position: absolute;
        left: 5%;
    }

    > .education-eyeball__cornea {
        position: absolute;
        height: 64px;
        width: 64px;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: 45%;

        &.--max {
            background-image: url('../../../assets/images/education/Education_Cornea-Max.svg');
            left: -10px;
        }

        &.--min {
            background-image: url('../../../assets/images/education/Education_Cornea-Min.svg');
        }
    }

    > .education-eyeball__crystalline {
        position: absolute;
        height: 72px;
        width: 72px;
        background-repeat: no-repeat;
        background-size: contain;
        left: 18%;
        bottom: 45%;

        &.--max {
            background-image: url('../../../assets/images/education/Education_Crystalline-Max.svg');
        }

        &.--min {
            background-image: url('../../../assets/images/education/Education_Crystalline-Min.svg');
        }
    }

    > .canvas {
    }
}
