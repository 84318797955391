.lens-consultation-side-v2 {
    height: 100%;

    &__top {
        position: relative;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        label {
            font-weight: 700;
            font-size: 1.2rem;
            color: #fff;
            display: flex;
            align-items: center;

            img{
                margin-right: 1rem;
                width: 1.5rem;
            }
        }
        .warning-icon{
            position: absolute;
            font-size: 1.5rem;
            font-weight: bold;
            color: red;
            margin-top: -2rem;
            right: 0;
            top: 0;
            border-radius: 15rem;
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.25rem;
            background-color: white;
            box-shadow: -1px -1px 10px 6px rgba(235,92,109,0.25);
            span{
                background-color: rgb( 235, 92, 109);
                border-radius: 15rem;
                width: 100%;
                height: 100%;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
            }

        }
        .dialogue{
            position: absolute;
            border-radius: 5pt;
            width: 26rem;
            height: 7rem;
            top: -2.6rem;
            left: 15.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: rgb( 235, 92, 109);
            color: white;
            font-weight: bold;
            font-size: 1.2rem;
            text-align: center;
            &::before{
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                left: -1rem;
                top: 1.65rem;
                border: .75rem solid transparent;
                border-top: none;
                transform: rotate(-90deg); 
                // looks
                border-bottom-color: rgb( 235, 92, 109);
                filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
            }

            &-buttons-container{
                position: relative;
                width: 100%;
                display: flex;
            }
            
            &-buttons{
                position: absolute;
                width: 100%;
                justify-content: center;
                display: flex;
                gap: 16px;
                top: 4px;

                &-yes, &-no{
                    width: 50px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                }

                &-yes{
                    background-color: #0086d6;
                }
                &-no{
                    border: 1px solid #BD4E58;
                    background-color: #eb5c6d;
                }
            }
        }

        &--sun {
            display: none;
        }
    }

    &__lens-pairs{
        display: flex;
        align-items: center;
        background: white;
        border-radius: 19pt;
        padding: 4pt;
        height: 5.2rem;
        width: 13.5rem;
        justify-content: center;
        position: relative;
        img{
            filter: invert(34%) sepia(97%) saturate(2049%) hue-rotate(180deg) brightness(93%) contrast(100%);
            transition: opacity .25s ease-in-out;
            width: 3.3rem;
        }
        .seprate{
            width: 1px;
            height: 1.5rem;
            display: inline-block;
            display: flex;
            align-items: center;
        }

        &--pair1{
            cursor: pointer;
            .pair1-selected{
                background-color: $theme;
                padding: 0.75rem;
                border-radius: 19pt;
                width: 6rem;
                display: flex;
                justify-content: center;
                img{
                    filter: brightness(0) invert(1);
                }
            }
            .pair2-selected{
                width: 6rem;
                display: flex;
                justify-content: center;
            }
        }
        &--pair2{
            cursor: pointer;
            .pair2-selected{
                padding: 0.75rem;
                background-color: $theme;
                border-radius: 19pt;
                width: 6rem;
                display: flex;
                justify-content: center;
                img{
                    filter: brightness(0) invert(1);
                }
            }
            .pair1-selected{
                width: 6rem;
                display: flex;
                justify-content: center;
            }
        }
    }

    &__bottom {
        position: relative;
        z-index: 3;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: auto;

        .button {
            margin-left: 1rem;

            &:first-child{
                padding: .65rem 1.5rem;
            }
        }

        &--remove {
            display: none;
        }
    }

    &__bottom-sun {
        position: absolute;
        z-index: 3;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        bottom:16px;
        right:16px;

        .button {
            margin-left: 1rem;

            &:first-child{
                padding: .65rem 1.5rem;
            }
        }

        &--remove {
            display: none;
        }
    }

    &__eye-blink{
        width: 2.5rem;
        margin-left: 1rem;
        position: relative;

        img{
            width: 100%;
            transition: transform .25s ease-in-out;
            filter: brightness(0) invert(1);

            &:first-child{
                position: absolute;
            }
        }

        .svg{
            opacity: 0;
        }
    }

    &--sun {
        .lens-animation,
        .lens-transition-in-animation{
            display: none;
        }

        .lens-transition-out{
            border-radius: 1rem;
        }
    }
}