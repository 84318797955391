.delivery-pair-details {
    height: 36rem;
    background-size: cover;
    border-radius: 1.5rem;
    overflow: hidden;
    margin: 5rem 0;

    &--1 {
        background-image: url('./../../../assets/images/delivery-summary-pair1.png');
    }

    &--2 {
        background-image: url('./../../../assets/images/delivery-summary-pair2.png');
    }

    &__benefit {
        &--sun {
            width: 5rem;
        }

        &--standard {
            width: 8.5rem;
        }
    }
    
    &__info{
        width: 56.3rem;
        height: 100%;
        background: rgba(47,59,74, 0.5);
        backdrop-filter: blur(10px);
        margin-left: auto;
        padding: 1.5rem 4rem 2.5rem;
        position: relative;
        display: flex;
        flex-flow: column;

        > label {
            width: 6.8rem;
            height: 4rem;
            @extend .flex-center;
            color: #fff;
            border-radius: .8rem;
            border: 1px solid;
            font-size: 1.5rem;
            position: absolute;
            top: 2rem;
            right: 3rem;
        }
    
        h2 {
            color: #fff;
            display: flex;
            align-items: center;
    
            span {
                font-size: 1.2rem;
                border-radius: .4rem;
                height: 2.3rem;                
                border: 1px solid;
                font-weight: normal;
                margin-left: 2rem;
                padding: 0 1rem;
                @extend .flex-center;
            }
        }
    
        .text {
            margin-top: 1rem;
        }
    
        ul {
            margin-top: auto;
            display: flex;
            align-items: center;
            justify-content: space-around;
    
            li {
                @extend .flex-center;
                flex-flow: column;
                color: #fff;
                width: 10rem;
                padding: 0 0.5rem;
            }
            .separator {
                width: 1px;
                height: 9.1rem;
                background-color: rgba(#fff, .1);
            }
        }
    }
}