.rating-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // grid-auto-rows: 140px;
    padding-top: 2rem;

    &__rating-box {
        padding: 10px 10px 10px 22px;
        border-radius: 8px;
        box-shadow: 0 2px 8px 0 rgba(47, 59, 74, 0.15);
        margin: 0 24px 15px;
        display: flex;
        height: fit-content;
        width: 426px;
        flex-direction: row;
    }

    &__rating-label {
        font-size: 10px;
        height: 100%;
        width: 60%;
        margin-right: 38px;
        align-self: center;
        display: inline-block;
        text-align: left;
    }

    &__rating-container {
        display: flex;
        flex-direction: row;
        align-content: stretch;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        height: 31px;
        width: 165px;
        border-radius: 3px;
        margin-left: auto;
        font-size: 8px;
        border-radius: 7px;
        background: linear-gradient(45deg, #F0A954 0%, #F95162 80%);

        overflow: hidden;
    }

    &__rating-input {
        display: none;

        // width:20%;
    }

    &__rating {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        background-color: #FDDFD2;
        color:rgb(242,150,85);
        height: 100%;
        cursor: pointer;
    }

    &__rating-fill, &__rating-selected {
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        background: transparent;
        height: 100%;

        line-height: 100%;
    
    }

    &__rating-fill {
        color: #ffffffb3;

    }

    &__rating-selected {
        color: #ffff;
        font-weight: bold;
    }

    &__rating-active {
        width: 20%;
        color: blue;
        align-self: center;
    }

    &__slider {
        >div {
            width: 100%;
        }
    }

    &__slider-value {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 8px;
        color: $theme;
        font-weight: bold;
        font-size: 12px;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .rating-group {
        overflow-y: auto;
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .rating-group {
        overflow-y: auto;
        grid-template-columns: 1fr 1fr;
    }
}