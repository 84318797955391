.button {
    border: none;
    padding: 1.2rem 1.5rem;
    border-radius: .7rem;
    font-weight: bold;
    transition: all .25s ease-in-out;
    min-height: 4rem;
    min-width: max-content;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;
    cursor: pointer;

    img{
        transition: all .25s ease-in-out;
    }

    // BUTTON TYPES
    &--primary {
        background-color: var(--app-theme);
        color: #fff;
        border: 1px solid var(--app-theme);
    }

    &--secondary{
        background-color: #fff;
        color: var(--app-theme);        
    }

    &--simple {
        background-color: transparent;
        border-color: #fff !important;
        color: #fff;

        &.button{
            &--active {
                background-color: #fff;
                color: $text-color-main;
            }
        }
    }

    &--disabled {
        // background: #c1c1c1;
        // color: #989898;
        // border-color: #c1c1c1 !important;

        // img{
        //     opacity: 0.35;
        // }
        opacity: .2;
    }
    // BUTTON TYPES END

    &--text {
        &-default {
            font-size: 1.5rem;
        }
        &-small {
            font-size: 1.2rem;
        }
    }

    &--outlined {
        border: 1px solid ;

        &.button {
            &--outline-colored {
                border-color: var(--app-theme);
            }
            &--outline-light{
                border-color: var(--app-theme-bg-light);
            }
            &--outline-none{
                border: none;

            }
        }
    }

    &--bold {
        font-weight: bold;
    }

    &--normal {
        font-weight: normal;
    }

    &--circle {
        border-radius: 50%;
    }

    &__icon, .circle-arrow, > .svg-icon{
        margin-left: 1.5rem;

        // max-width: 2rem;
        // width: 100%;
    }

    &--icon-only {
        height: 4rem;
        width: 4rem;
        min-width: 4rem;
        padding: 0;
        display: flex;
        justify-content: center;
        
        .button__icon,
        > .svg-icon  {
            margin-left: 0;
        }   
    }

    &--icon-left {
        flex-flow: row-reverse;

        img{
            margin-right: 1rem;
            margin-left: initial;
        }

        .circle-arrow{
            margin-left: 0;
            margin-right: 1.5rem;
        }
    }

    &--themed {
        &.button{
            &--primary {
                background-color: var(--app-theme-main);
                color: #fff;
                border: 1px solid var(--app-theme-main);
            }

            &--secondary {
                color: var(--app-theme-main);
            }
        }
    }

    &--soon{
        position: relative;
        overflow: hidden;
        cursor: unset;
        .soon-hover{
            display: none;
        }
        
        &:hover { 
            background: #fff !important;            
            color: var(--app-theme);
            text-align: center;
            display: flex;
            align-items: center;  
            justify-content: center;  
            padding: 1.2rem 1.5rem;
            // &:after{
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 100%;
            //     background: #fff;
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            //     color: $theme;
                
            //     }
            .soon-hover{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .text-placement{
                opacity:0;
            }
            .button__icon{
                opacity:0;
            }
            .icon{
                display: none;
            }
        } 
    }

    &--sun {
        &-back{
            background-color: #fff;
            color: var(--app-theme);    
            border: 1px solid var(--app-theme);
            font-size: 1.2rem !important;
            min-height: 3rem !important;
            padding:8px !important;
        }
        &.button {
            &--soon {
                &:hover {
                    border: 1px solid var(--app-theme) !important;
                }
            }
        }
    }

    &--not-available{
        position: relative;
        overflow: hidden;
        &:hover {
            &::after{
                content: 'Not available during pilot';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--app-theme);
                font-size: 1.3rem;
            }
        }
    
    }
}

.button--sun{
    &.button {
        &--primary {
            background: var(--app-sun-gradient-bg);
            border: none;
        }

        &--secondary{
            border-color: var(--app-sun-theme);
            color: var(--app-sun-theme);  
        }
    }
}