.tint-selection-step {
    top: 0;
    left: 0;
    width: 100%;
    height: max-content;

    display: flex;
    flex-flow: column;

    box-sizing: border-box;

    &__labels {
        padding: 3rem 3rem 1rem;

        label {
            color: var(--app-sun-theme);
        }
        
        h4{
            display: flex;
            align-items: center;
            justify-content: space-between;

            span{
                font-size: 1rem;
                background: linear-gradient(95deg, var(--app-tint-gradient-dark) 0%, var(--app-tint-gradient-light) 100%);
                color: #fff;
                padding: .25rem 1.5rem;
                font-weight: normal;
                border-radius: 2rem;
                text-transform: capitalize;
            }
        }
    }

    &__tints {
        padding: 0rem 3rem 3rem 3rem;
        border-bottom: 2px solid rgba(90, 102, 117, 0.1);
        height: max-content;
    }

    &__product{
        height:12rem;
        width: 100%;
        padding:16px 16px 32px 16px;
        border-bottom: 2px solid rgba(90, 102, 117, 0.1);
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-edit{
            display: flex;
            align-items: center;
            justify-content: space-around;
            border: 1px solid #f35d1d;
            height: 2.5rem;
            width: 6.5rem;
            border-radius: 15px;
            padding-inline:5px;
            padding-left: 10px;
            color:#f35d1d;
            font-size: 1rem;
            font-weight: bold;
        }
    }
    &__product-family, &__enhance-label{
        width: max-content;
        font-size: 1.2rem;
        padding-left: 16px;
        position: relative;
        background: var(--app-sun-gradient-bg);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &::before{
            content: '';
            position: absolute;
            height: 7px;
            width: 7px;
            background: var(--app-sun-gradient-bg);
            left: 5px;
            top: 5px;
            border-radius: 50%;

        }
    }

    &__product-label, &__enhance-product{
        font-weight: bold;
        background: #FEF5F1;
        height: 4.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 1.5rem;
        border-radius: 5px;
        font-size: 1.12rem;

    }
}