@import './product-description';
@import './product-demo';
@import './product-demo-selection';
@import './product-demo-compare';
@import './see-through-demo';
@import './draw-line';
@import './demo-text-overlay';


.demonstrations {
    display: flex;
    flex-flow: column;
    background: #eee;
    
    &--page {
        height: $pageHeight;
        position: relative;
    }

    &__back{
        position: absolute;
        top: 3rem;
        left: 3.5rem;
        z-index: 3;
    }

    &--modal {
        height: calc(100% - 7rem);
    }


    &__canvas {
        flex: 1;
        position: relative;
        display: flex;
        align-items: flex-end;
        overflow: hidden;

        &--vertical-slider-video{
            flex-flow: row-reverse;
        }
    }
    
    &__controls {
        padding: 0 3.5rem;
        height: 8.1rem;
        background-color: $background-dark;
        display: flex;
        align-items: center;
        position: relative;

        .button-group{
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }

        &-disabled {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($background-dark, .9);
        }
    }

    &__family {
        margin-left: auto;
    }

    &__preload{
        @extend .flex-center;
        flex-flow: column;
        height: 100%;
        
        label {
            color: var(--app-theme);
            margin-top: 1rem;
        }
    }

    &__nested-demo {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;

        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-flow: column;
        padding: 2rem;

        &--loading {
            label{
                &:not(.active){
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
        }


        label {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            background-color: rgba(#fff, .9);
            color: #fff;
            cursor: pointer;
            margin-bottom: 2rem;
            box-shadow: 0 0 0px 2px rgba($theme, 1);
            @extend .flex-center;
            opacity: 0.9;
            transition: all .25s ease-in-out;

            img{
                width: 2.5rem;
                filter: brightness(0);
                transition: all .25s ease-in-out;
            }

            &.active{
                box-shadow: 0 0 0px 2px #fff;
                background-color: $theme;
                opacity: 1;
                transform: scale(1.15);

                img{
                    filter: brightness(0) invert(1);
                }
            }
        }
    }

    &__children {
        margin-left: auto;
    }

    &__vto-qrCode{
        position: absolute;
        top: 2rem;
        right: 5rem;
        z-index: 20;
        display: flex;
        &--page {
            top: 3rem;
        }

        &::after{
            content: 'New!';
            right: -4rem;
            top: -9px;
            position: absolute;
            background-color: $theme;
            color: #fff;
            font-size: 1.5rem;
            border-radius: 5px;
            width: 5rem;
            height: 3rem;
            border: 1px solid #fff;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__control-children {
        position: absolute;
        top: 2rem;
        left: 4rem;
        z-index: 20;
        display: flex;

        .button {
            margin-right: 1rem;
        }

        &--page {
            top: 10rem;
        }
    }

    &__qrcode-container{
        position: relative;
        height: 100%;
        width: 100%;
        z-index:20;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__qrcode-wrapper{
        position: absolute;
        height: 38rem;
        width: 65rem;
        border-radius: 2rem;
        border: 2px solid $theme;
        background:rgba(0, 0, 0, 0.7);
        animation: zoom 0.2s ease-in-out forwards;
    }

    @keyframes zoom {
        0%{opacity: 0; width: 0rem;}
        50%{opacity: 0.5; width: 33rem; }
        100%{opacity: 1; width: 65rem;}
    }

    &__qrcode{
        display: flex;
        flex-wrap: wrap;
        height: 77%;
        width: 100%;
    }
    &__qrcode-icon-placement{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
    }
    &__qrcode-icon{
        padding-bottom: 2rem;
        width: 50%;

        img{
            width: 120%;
            object-fit: cover;
        }
    }
    &__qrcode-label{
        width: 50%;
        height: 100%;
        color: #fff;
        font-size: 1.5rem;
        padding: 2rem;

        ul{
            display: grid;
            gap: 1.5rem;
        }

        &-title{
            font-size: 2.8rem;
            font-weight: bold;
        }

        &-step{
            display: grid;
            grid-template-columns: 1fr 10fr;
            padding-bottom:15px;
            height: 25%;
        }

    }

    &__qrcode-header{
        position: relative;
        width: 100%;
        background: $theme;
        height: 5rem;
        border-top-left-radius:1.5rem;
        border-top-right-radius:1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        color:#fff;

        &-icon{
            position: absolute;
            right: 2rem;
            cursor: pointer;
        }
    }

    &__qrcode-footer{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 10%;
        width: 100%;
        font-size: 1rem;
        color: #fff ;
        text-align: center;
    }
}