.frame-selection-step {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    height: 100%;

    &__labels{
        padding: 1.5rem 0 1rem;
        label{
            color: var(--app-sun-theme);
            font-size: 1rem;
            margin-bottom: .5rem;
            display: block;
        }
    }

    .button-tab{
        margin: 2rem 0;
    }

    &__next {
        margin-top: 1rem;
        margin-left: auto;
    }
}