.measure-step-navigation{
    position: relative;
    z-index: 2;

    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-flow: column;
    width: 20rem;

    &__step {
        flex: 1;
        position: relative;
        padding-left: 3rem;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
        color: $theme;
        cursor: pointer;


        &-label {
            display: none;
            font-weight: bold;
            margin-bottom: 1rem;
            font-size: 1.25rem;
        }

        h3 {
            display: none;
            font-weight: normal;
        }


        &--active {
            &:after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: .5rem;
                background-color: $theme;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                box-shadow: 0px 0 11px 0 $theme;
            }

            label,h3 {
                display: block;
            }
        }

    }
}

.measure-step-navigation {
    &--sun {        
        .measure-step-navigation {
            &__step {
                &--active {
                    color: var(--app-sun-theme);

                    &::after {
                        background-color: var(--app-sun-theme);
                        box-shadow: 0px 0 11px 0 var(--app-sun-theme);
                    }
                }
            }
        }
    }
}