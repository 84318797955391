* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.summary-details {
    &__data {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 4rem;
    }
    
    @media screen and (max-width:1024px) {
        &__data{
            display:grid;
            grid-template-columns:1fr;
            grid-gap:4rem;
        }
    }

    &__lens {
        border: 1px solid var(--app-theme-bg-lighter);
        border-radius: 8px;
        padding: 3rem;
        display: flex;
        align-items: flex-start;
        flex-flow: column;
        height: max-content;
        box-shadow: 0 20px 20px 15px var(--app-theme-shadow);
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        box-sizing: border-box;
    }

    &__lens-type,
    &__lens-thickness {
        margin-right: 1rem;
        margin-bottom: .5rem;
        width: 100%;
        height: 6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__lens-type label,
    &__lens-thickness label {
        padding: .5rem 2rem;
        border-radius: 20px;
        color: var(--app-theme);
        border: 1px solid var(--app-theme-bg-light);
        font-size: 1rem;
        font-weight: bold;
        display: inline-block;
        background-color: var(--app-theme-bg-light);
    }

    &__lens-type span,
    &__lens-thickness span {
        cursor: pointer;
    }

    &__lens-products {
        width: 100%;
    }

    &__lens-product,
    &__lens-thickness-calculation {
        color: #2F3B4A;
        font-size: 2rem;
        border-radius: 3px;
        line-height: 24px;
        font-weight: bold;
        margin-top: 2rem;
    }

    &__lens-desc {
        margin-top: 2rem;
        width: 100%;

        > ul > li > label {
            font-size: 1.5rem;
        }
    }

    &__lens-product li {
        height: 7rem;
        width: 100%;
        margin-bottom: 5px;
        border-radius: 5px;
        display: flex;
        background-color: var(--app-theme-bg-lighter);
        align-items: center;
        overflow-y: hidden;
        padding: 0.5rem;
        gap: 1rem;
        font-size: 1rem;
        font-weight: normal;
    }

    &__qr-code-icon{
       margin-right: 3rem;

       span{
        font-size: 10px;
        font-weight: 800;
       }
    }

    &__icons-container{
        display: flex;
    }
}