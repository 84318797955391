.product-demo-selection {
    flex: 1;
    z-index: 5;
    position: relative;

    &__selected {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: absolute;
        bottom: 3rem;
        width: 100%;
        min-width: max-content;
        gap: 1rem;

        .button {
            height: 4rem;
        }
    }

    &__sub-products {
        padding: 4.4rem 3.5rem 2.5rem;
        background-color: rgba($background-dark, 0.8);
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        grid-template-rows: 1fr;
        grid-auto-rows: 1fr;
        grid-gap: 1rem;
        position: relative;
        transform: translateY(100%);
        transition: all .5s ease;
        position: absolute;
        bottom: 0;

        &--open {
            transform: translateY(0%);
        }

        span {
            height: .5rem;
            width: 4rem;
            background: #fff;
            border-radius: .2rem;
            display: block;
            position: absolute;
            top: 1.5rem;
            left: 0;
            right: 0;
            margin: auto;
            cursor: pointer;
        }

        &--vertical-slider-video {
            display: none;
        }
    }

    &__product {
        // width: 31rem;
        height: 4rem;
        font-size: 1.5rem;
        color: #fff;
        background-color: $background-light;
        border-radius: .8rem;
        border: none;
        @extend .flex-center;
        outline: none;
        cursor: pointer;

        &--active {
            background: var(--app-theme);
            font-weight: bold;
        }
    }
}