.lens-family-sun{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12.5rem;

    &__product{
        width: 100%;
        padding:16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        &--disabled {
            opacity: 0.4;
            pointer-events: none;
        }
        
        &-edit{
            display: flex;
            align-items: center;
            justify-content: space-around;
        border: 1px solid #f35d1d;
        height: 2.5rem;
        width: 6.5rem;
        border-radius: 15px;
        padding-inline:5px;
        padding-left: 10px;
        color:#f35d1d;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
    }
    &-family{
        width: max-content;
        font-size: 1.2rem;
        text-transform: capitalize;
        padding-left: 16px;
        position: relative;
        background: var(--app-sun-gradient-bg);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        
        &::before{
            content: '';
            position: absolute;
            height: 7px;
            width: 7px;
            background: var(--app-sun-gradient-bg);
            left: 5px;
            top: 5px;
            border-radius: 50%;
            
        }

        &-add{
            height:2.8rem;
            width:2.8rem;
            border-radius: 2rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border:1px solid var(--app-theme);
            transition: all .25s ease-in-out;
            &:hover{
                transition: all .25s ease-in-out;
                 background:var(--app-theme);
                 img{
                     filter: brightness(0) invert(1);
                 }
             }
        }
    }
}

&__product-label, &__enhance-product{
    font-weight: bold;
    background: #FEF5F1;
    height: 4.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1.5rem;
    border-radius: 5px;
    font-size: 1.12rem;
}
}