@import './_lens-proposal-builder-card';
@import './_lens-proposal-builder-experience';
@import './_lens-proposal-builder-footer';

.lens-proposal-builder {
    height: $pageHeight;
    overflow: hidden;

    &__main {
        height: 100%;
        position: relative;
        z-index: 3;

        display: grid;
        grid-template-rows: 1fr 1fr 1fr 5rem;
        grid-template-columns: 1fr 1fr;

        grid-gap: 2rem;
        padding: 3rem;
        background: #fff;
        transition: all .5s ease-in-out;

        &--experienced {
            transform: translateY(-10rem);
            border-radius: 3rem;

            .lens-proposal-builder__overlay {
                pointer-events: initial;
                background: rgba(#fff,0.9);
                opacity: 1;
            }
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 5;
        border-radius: 3rem;
        opacity: 0;
        transition: all .5s ease-in-out;
        pointer-events: none;
    }


    .impacted {
        position: absolute;
        bottom: 0;
        left: 0;
        color: #fff;
        background: $theme;
        width: 100%;
        padding: 1.1rem 3.25rem;
        font-size: 1.25rem;
        
        span {
            border: 1px solid #fff;
            padding: .25rem 1rem;
            border-radius: .5rem;
            display: inline-block;
            margin-left: 1rem;
        }
    }


    &__search-id-popup {
        display: flex;
        flex-flow: column;
        align-items: center;

        h2,p {
            margin-bottom: 2rem;
        }

        p {
            font-size: 1.5rem;
            color: var(--app-theme);
        }

        > div {
            display: flex;
            align-items: center;
            justify-content: center;

            input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                width: 25rem;
                border-right: none;
            }

            button {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                height: 4.25rem;
                transform: translateX(-2px);
            }
        }
    }

}

.lens-proposal-builder {
    &__main--sun {
        grid-template-rows: 1fr 16rem 5rem;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &__message-status {
        font-size: 1.8rem;
        color: $text-color-light;
        margin-left: auto;
        display: flex;
        align-items: center;

        &-icon {
            margin-right: 1rem;
        }

        &--complete {
            color: $theme;


            .button {
                background-color: $theme;
                border: none;
            }
        }
    }
}