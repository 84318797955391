.multiple-choice {

    &__choice {
        height: 4rem;
        width: 100%;
        border: 1px solid var(--app-sun-theme);
        border-radius: .7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--app-sun-theme);
        font-size: 1.5rem;
        font-weight: bold;
        cursor: pointer;

        &:not(:last-child){
            margin-bottom: 1rem;
        }

        &--selected {
            border: none;
            background: var(--app-sun-gradient-bg);
            color: #fff;
        }
    }

    &__description {
        font-size: 1rem;
        font-weight: normal;
    }

    &--with-desc {
        li {
            padding: 0 1.5rem;
            justify-content: space-between;
        }
    }
}