.choice-box-image {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 280px 280px;
  grid-gap: 2.5rem;
  padding-right: 2rem;

  &.-one-row {
    grid-template-rows: 25rem;
  }

  &__item {
    height: 100%;
    border-radius: 12px;
    transition: all 0.25s ease-in-out;
    position: relative;
    cursor: pointer;

    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 1rem 1rem 1.5rem 1.5rem;
    margin: 8px;

    font-size: 1.35rem;
    font-weight: bold;
    color: #fff;
    overflow: hidden;

    label {
      position: relative;
      cursor: pointer;
    }

    div {
      background-color: -webkit-gradient(linear,
          top,
          rgba(0, 0, 0, 0.5),
          rgba(200, 20, 200, 0.5));
    }

    >div:last-child {
      width: 100%;
      display: flex;
      height: max-content;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.95);
      border-radius: 5px;
      padding: 1.5rem;
    }

    >div:last-child>.label {
      font-size: 12px;
    }

    >div:last-child>.label.-black {
      color: black;
    }

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      >div:last-child {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    &--selected {
      box-shadow: 0 0px 0px 4px $seafoamblue, 0 0px 7px 3px #0000006e;

      .choice-box-image__check {
        background: $seafoamblue;
        border: 0;

        &::after {
          opacity: 1;
        }
      }
    }
  }

  &__item.-clear {
    border: 1px solid $cerulean-10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >div:last-child() {
      background: transparent;
      backdrop-filter: unset;
      justify-content: center;
    }

    .choice-box-image__check {
      top: 4%;
      position: absolute;
      align-self: flex-end;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-clip: border-box;
    background-repeat: no-repeat;
    background-position-y: top;
    cursor: pointer;
    flex: 1;
  }

  &__image.-clear {
    position: relative;
    flex: none;
    width: 100%;
    display: block;
    height: auto;
    max-width: 15rem;
  }

  &__check {
    position: relative;
    display: block;
    width: 1.75rem;
    height: 1.75rem;
    border: 1px solid white;
    border-radius: 2px;
    margin-left: auto;

    &::after {
      opacity: 0;
      content: '';
      position: absolute;
      height: 4px;
      width: 7px;
      border-left: 1px solid white;
      border-bottom: 1px solid white;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .choice-box-image {
    grid-template-rows: 10rem 10rem;
    grid-auto-rows: 10rem;

    .choice-box-image__item {
      div:last-child() {
        padding: 0 4px;
        height: 64px;
      }

      label {
        font-size: 8px;
      }
    }
  }
}

@media only screen and (min-device-width: 590px) and (max-device-width: 1064px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .choice-box-image {
    grid-template-rows: 16rem 16rem;
    grid-auto-rows: 16rem;
    grid-template-columns: 2fr 2fr;

    .choice-box-image__item {
      div:last-child() {
        padding: 0 4px;
        height: 64px;
      }

      label {
        font-size: 12px;
      }
    }
  }
}

@media only screen and (min-width: 1517px) and (max-width: 1707px) and (orientation: landscape) {
  .choice-box-image.-one-row {
    grid-template-rows: 30rem;

  }
}

@media only screen and (min-width: 1707px) and (max-width: 2000px) and (orientation: landscape) {
  .choice-box-image.-one-row {
    grid-template-rows: 35rem;

  }
}

@media only screen and (min-width: 2000px) and (max-width: 2500px) and (orientation: landscape) {
  .choice-box-image.-one-row {
    grid-template-rows: 40rem;

  }
}
