.continue-with-id {
   @extend .flex-center;

    position: absolute;
    top: 14rem;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 1;

   > div{
        @extend .flex-center;
        background-color: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(10px);
        border-radius: 1rem;
        border: 1px solid #fff;
        margin-left: 1rem;
        justify-content: space-between;
        width: 34.5rem;

        

        label{
            padding: 0 2rem;
            font-size: 1.5rem;
            font-weight: 600;
        }
   }
}