.advantage-item {
    margin-bottom: 1rem;

    &__label {
        font-size: 1rem;
        margin-bottom: .5rem;
        display: block;
        display: flex;
        justify-content: space-between;
        
    }

    &__progress {
        background: var(--app-sun-theme-bg-light);
        border-radius: .7rem;
        overflow: hidden;

        div {
            width: 50%;
            height: .4rem;
            background: var(--app-sun-gradient-bg);
            border-radius: .7rem;
        }
    }
}