.tutorial-main{
    &__intro{
        
        display: flex;
        height: 25rem;
        border-radius: 1.5rem;
        overflow: hidden;
        margin: 5rem 0;
    }
    &__background{
        background-image: url('./../../../assets/images/tutorial-background.jpg');        
        background-size: cover;
        width: 51%;
    }
    &__info{
        width: 51.3rem;
        height: 100%;
        background: rgba(47, 59, 74, 0.5);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        margin-left: auto;
        padding: 3rem;
        position: relative;
        display: flex;
        flex-flow: column;
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        justify-content: center;

        p{
            color: white;
            font-size: 1.3rem;
            margin-bottom: 3rem;
        }
        h5{
            color: white;
            font-weight: bold;
            img{                
                margin-right: 2rem;
                height: 1.5rem;
            }
        }
        hr{
            border-top: 1px solid white;          
            opacity: 10%;
            margin: 1rem 0;
        }
    }

    .accordion--parent.tutorial-content{
        label{
            font-weight: bold;
        }
    }
    .accordion{
        border-top: none;
        border-bottom: 1px solid rgba(90, 102, 117, 0.2);
        &__body{
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 1rem;
            gap: 1.5rem;
            display: grid;
            padding: 0;
        }
        &__head{
            border-left: none;
            padding: 4rem 3rem 4rem 0rem;
            &--open{
                background-color: transparent;
            }
        }
        &__arrow-wrapper{
            background-color: rgba(47,59,74,0.1);
            border-radius: 50%;
            transition: all .25s ease-in-out;
            &.up{
                background-color: var(--app-theme);
            }
            .arrow.arrow--up{
                border-color: white;
            }
        }
    }
    .accordion--parent > .accordion__head--open{
        background-color: transparent;
    }
}