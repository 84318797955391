.lens-consultation-brand{
    flex: 1;
    padding-top: 8rem;
    
    .button {
        border-color: $border-color-light;
        margin-bottom: 4rem;
    }

    &__list {
        margin-right: .5rem;
        li {
            height: 6rem;
            font-size: 1.75rem;
            padding: 0 3rem;
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid $border-color-light-blue;
            border-radius: 12px;
            margin-bottom: 1.5rem;
            transition: all .25s ease-in-out;
            
            &:hover {
                border-color: $theme;
            }
        }

        li.disabled {
            display:none;
        }
    }

    
}