.need-explorer-question {
  width: 100%;
  display: flex;
  flex-flow: column;
  height: calc(100vh - 26rem);
  margin-bottom: 1rem;
  overflow: auto;

  &__question {
    margin-bottom: 16px;

    &--with-subquestion {
      margin-bottom: 1rem;
    }

    span {
      font-weight: 100!important;
    }
  }

  &__question-sun {
    text-align: center;
    margin-bottom: 10px;
    font-size: 2rem;
    width: 50%;
    text-align: center;
    margin: 0 auto;

    span {
      font-weight: 100!important;
    }
  }

  &__question-type {
    color: $theme;
    margin-bottom: 16px;
  }

  &__question-type-sun {
    color: var(--app-theme);
    margin-bottom: 16px;
    text-align: center;
  }

  &__sub-question {
    font-size: 1.5rem;
    color: $text-color-light;
    margin-bottom:
      2rem;

  }
}

// sun exp
.need-explorer-question-sun {
  width: 100%;
  height: 591px;
  display: flex;
  align-items:center;
  flex-flow: column;
  height: calc(100vh - 26rem);
  margin-bottom: 1rem;

  &__question {
    margin-bottom: 16px;

    &--with-subquestion {
      margin-bottom: 1rem;
    }

    span {
      font-weight: 100!important;
    }
  }

  &__question-sun {
    text-align: center;
    margin-bottom: 10px;
    font-size: 2rem;
    width: 50%;
    text-align: center;
    margin: 0 auto;

    span {
      font-weight: 100!important;
    }
  }

  &__question-type {
    color: $theme;
    margin-bottom: 16px;
  }

  &__question-type-sun {
    color: var(--app-theme);
    margin-bottom: 16px;
    text-align: center;
  }

  &__sub-question {
    font-size: 1.5rem;
    color: $text-color-light;
    margin-bottom:
      2rem;

  }
}