.lens-consultation-side-sun {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: opacity 1s ease-in-out;
    height: 100%;
    width: 100%;

    &--fadeout {
        opacity: 0;
    }

    &__product{
        height:12rem;
        width: 100%;
        padding:16px 16px 32px 16px;
        border-bottom: 1px solid rgba(90, 102, 117, 0.1);
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-edit{
            display: flex;
            align-items: center;
            justify-content: space-around;
            border: 1px solid #f35d1d;
            height: 2.5rem;
            width: 6.5rem;
            border-radius: 15px;
            padding-inline:5px;
            padding-left: 10px;
            color:#f35d1d;
            font-size: 1rem;
            font-weight: bold;
        }
    }
    &__product-family, &__enhance-label{
        width: max-content;
        font-size: 1.2rem;
        padding-left: 16px;
        position: relative;
        background: var(--app-sun-gradient-bg);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &::before{
            content: '';
            position: absolute;
            height: 7px;
            width: 7px;
            background: var(--app-sun-gradient-bg);
            left: 5px;
            top: 5px;
            border-radius: 50%;

        }
    }

    &__product-label, &__enhance-product{
        font-weight: bold;
        background: #FEF5F1;
        height: 4.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 1.5rem;
        border-radius: 5px;
        font-size: 1.12rem;

    }
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 3rem;

        > button {
            height: 4rem;
            margin-left: 1rem;
        }

        .blink-eye{
            margin-left: 1rem;
        }
    }

    .button--soon:hover {
        .blink-eye {
            opacity: 0;
        }
    }
}