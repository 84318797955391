.list-menu {
    // border: 2px solid $border-color-light-blue;
    // border-radius: 13px;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    cursor: pointer;

    &__item {
        border-bottom: 2px solid var(--app-theme-bg-light);
        border-left: 2px solid var(--app-theme-bg-light);
        border-right: 2px solid var(--app-theme-bg-light);
        padding: 0 3.5rem;
        height: 6rem;
        display: flex;
        align-items: center;
        transition: all .25s ease-in-out;

        &--selected{
            background-color: var(--app-theme);
            border: none;
            color: #fff;
            font-weight: bold;

            .list-menu__item-icon{
                filter: invert(1);
                img{
                    filter: brightness(0);                    
                }
            }

            > *:last-child{
                margin-left: auto;
            }
        }

        &:first-child{
            border-top-left-radius: 13px;
            border-top-right-radius: 13px;
            border-top: 2px solid var(--app-theme-bg-light);
        }
        &:last-child{
            border-bottom-left-radius: 13px;
            border-bottom-right-radius: 13px;
        }

        &-label{
            font-size: 1.65rem;
            cursor: pointer;

        }
        &-icon{
            margin-right: 2rem;
        }
    }


    &--S {
        .list-menu {
            &__item{
                height: 5rem;
                
                &:first-child{
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                }
                &:last-child{
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}

.list-menu--sun {
    .list-menu__item{
        &--selected {
            background: var(--app-sun-gradient-bg);
        }
    }
}