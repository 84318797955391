.table-list{    
    &__container{
        display: flex;
        min-height: 99%;
        flex-direction: column;
        margin: 0 1.5rem;
    }
    &__header{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 3rem;
        &--col{
            &.container{
                margin: 2rem 0;
                display: flex;
                align-items: center;
                label{
                    font-size: 1.5rem;
                    width: 19rem;
                    border-bottom: 1px solid $theme;
                    border-radius: .7rem;
                    padding: 1rem;
                    display: flex;
                    flex-direction: column;
                    height: 7.5rem;
                    justify-content: space-between;
                    span{
                        font-size: 1.2rem;
                        margin-bottom: 0.5rem;
                        color: #0086d6;
                        font-weight: bold;
                    }
                    
                }
                img{
                    transform: rotate(90deg);
                    height: 1.7rem;
                }
            }
            
        }
    }
    &__button-container{
        
    }

    &__content{
        max-height: 38rem;
        overflow-y: auto;
        th{
            border: 1px solid $theme;
            padding: 0.8rem;
        }
        tr, td{
            border: 1px solid $theme;
            padding: 0.5rem;
            text-align: center;
            color: $theme;
        }
        .option{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .input-text{
                max-width: 89px;
                border: unset;
                border-bottom: 1px solid $theme;
                padding: 0 0.5rem;
            }
            .button{
                margin: 0.3rem;
            }
        }
        
    }
    &__modal-container{
        height: 16rem;
        &--price-view{
            margin-top: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .input-text{
                margin-right: 1rem;
            }
        }

        &--list {
            flex: 1;
            height: calc(100vh - 24rem);
        }
        &--list-view{
            margin-top: 4rem;
            max-height: 40rem;
            overflow-y: auto;
        }
        
    }
    &__footer-button{
        position: absolute;
        bottom: 2rem;
        right: 2rem;
        display: flex;
        .button{
            margin-right: 1.5rem;
        }
    }
}