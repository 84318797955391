.no-rx-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__page {
        display: flex;
        align-items: center;
    
        li{
            span{
                width: 0.4rem;
                height: 0.4rem;
                background-color: $theme;
                border-radius: 50%;
                background-color: #ddd;
                display: block;
                transition: all .25s ease-in-out;
            }

            &.active span {
                background-color: var(--app-sun-theme);
                width: 0.7rem;
                height: 0.7rem;    
            }
        }

        li:not(:last-child){
            margin-right: 1rem;
        }
    }

    &__buttons {
        display: flex;

        .button:first-child {
            margin-right: 1rem;
        }
    }

    &__back {
        margin-right: 2rem !important;
        padding-right: 2rem;
        border-right: 1px solid #eee;
        cursor: pointer;
    }
}