.slide-view {
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__slider {
        display: flex;
        height: 100%;
        transition: transform 1s ease-in-out;

        &-sun{
            display: flex;
            height: 100%;
            transition: transform 1s ease-in-out;
            > div {
                flex: 1;
                opacity: 0;
                transition: opacity .9s ease-in-out;
                
                &.active-slide {
                    opacity: 1;
                    overflow-y: auto;
                }
            }
        }
        
        > div {
            flex: 1;
            opacity: 0;
            transition: opacity .9s ease-in-out;
            
            &.active-slide {
                opacity: 1;
                overflow-y: auto;
            }
        }
    }
}