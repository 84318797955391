.education-figure {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50%;
    margin-top: 32px;

    &__slider > .item-slider__object > .itemobject {
        display: flex;
        align-items: center;
        flex: 1;

        > .item {
            &.--standard {
                background: $theme;
                height: 40px;
                text-align: center;
                transform: rotate(45deg);
                width: 40px;
            }
        }

        > .slider {
            height: 160px;
            width: 80px;
            border-bottom-right-radius: 160px;
            border-top-right-radius: 160px;
            border: 2px solid $theme;
            display: inline-block;
            border-left: 0;
            left: 6rem;
            position: absolute;
        }
    }

    &__lens {
        flex: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;

        &.--concave-max {
            background-image: url('../../../assets/images/education/Education_Lens_Concave_Max.svg');
        }

        &.--concave-min {
            background-image: url('../../../assets/images/education/Education_Lens_Concave_Min.svg');
        }

        &.--convex-max {
            background-image: url('../../../assets/images/education/Education_Lens_Convex_Max.svg');
        }

        &.--convex-min {
            background-image: url('../../../assets/images/education/Education_Lens_Convex_Min.svg');
        }
    }

    > .eyeball {
        flex: 3;
    }
}
