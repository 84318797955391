.tint-selection {
    border-bottom: 1px solid $border-color-lighter;
    padding: 1.5rem 0;
    transition: all .5s ease-in-out;

    &--open{
        padding-bottom: 0;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.1rem;

        > label {
            cursor: pointer;
        }

        > div {
            display: flex;
            align-items: center;
            transform: translateX(-5px);
        }
    }

    &__arrow {
        transform: translateY(1px);
        cursor: pointer;
    }

    &__info-tooltip {
        margin-right: 1.5rem;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;        
        z-index: 80;
        user-select: none;
        color: #fff;
        

        &-text {
            font-size: 1.2rem;
            margin-right: 1rem;
        }

        &-toggle {
            border: 1px solid #ddd;
            border-radius: 50%;
            width: 1.6rem;
            height: 1.6rem;
            outline: none;
            text-align: center;
            font-size: 1rem;
            cursor: pointer;
            color: #ddd;
        }

        .generic-tool-tip__wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__selection {
        display: flex;
        padding: 1rem 0 0 0;
        margin-left: 8px;

        &-colors{
            display: flex;
            flex-wrap: wrap;
        }

        li{
            width: 1.6rem;
            height: 1.6rem;
            background: linear-gradient(90deg, var(--app-tint-gradient-dark) 0%, var(--app-tint-gradient-light) 100%);
            border-radius: 50%;
            margin-bottom: 1rem;
            cursor: pointer;
            &:not(:last-child) {
                margin-right: 1rem;
            }

            &.selected {
                box-shadow: inset 0px 0px 0px 2px #325080;
                position: relative;
                
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -.5rem;
                    left: 0;
                    width: 100%;
                    height: .2rem;
                    background-color: #325080;
                }
            }
        }
    }
}