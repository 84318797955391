@import './prescription-side';
@import './prescription-input';
@import './prescription-form';
@import './prescription-form-v2';
@import './prescription-lens-proposal';
@import './prescription-lens-proposal-sun';
@import './prescription-lens-overlay-animation';
@import './no-rx-modal';
@import './prescription-lens';
@import './prescription-sunwear';



.prescription {
    &--sun{
        --app-theme: var(--app-sun-theme);
        --app-theme-bg-light: var(--app-sun-theme-bg-light);
    }

    display: flex;

    &__main{
        flex: 1;
    }

    &__no-rx {
        font-size: 1.5rem;
        color:#fff;
        position: absolute;
        right: 3.25rem;
        bottom: 3rem;
        text-decoration: underline;
        cursor: pointer;
        padding:1.2rem 1.5rem;
        border-radius: 0.7rem;
        font-weight: bold;
        background: var(--app-sun-gradient-bg)
    }

}
