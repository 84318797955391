.prescription-lens-proposal-sun {
    padding: 0 4rem;

    &__text {
        margin-top: 3rem;
        margin-bottom: 4rem;

        label {
            color: var(--app-sun-theme);
            font-size: 1rem;
            display: block;
            margin-bottom: 1rem;
        }
    }

    &__choice {
        display: grid;        
        grid-template-columns: 24.7rem 24.7rem;
        grid-template-rows: 27.4rem;
        grid-gap: 2rem;
        justify-content: center;

        &-item {
            width:100%;
            height:100%;
            background-color: #eee;
            border-radius: .9rem;
            position: relative;
            display: flex;
            transition: all .25s ease-in-out;

            &--selected {
                box-shadow: 0px 0px 0px 3px var(--app-sun-theme);
            }


            &-bg {
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
                position: absolute;
                border-radius: .9rem;
            }

            &--selected {
                .prescription-lens-proposal-sun__choice-info{
                    background-color: rgba(#F35D1D, .5) !important;
                }
            }
        }

        &-info {
            position: relative;
            margin-top: auto;
            padding: 2.5rem;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(5px);
            height: 12rem;
            border-bottom-left-radius: .9rem;
            border-bottom-right-radius: .9rem;


            label {
                background-color: #fff;
                color: var(--app-sun-theme);
                padding: 0.2rem 1.5rem;
                font-size: 1rem;
                border-radius: 1rem;
                font-weight: bold;
                margin-bottom: 1rem;
                display: inline-block;
            }

            p {
                font-size: 1.8rem;
                color: #fff;
            }
        }
    }

    &__tip {  
        display: flex;
        align-self: center;
        justify-content: center;

        label {
            color: #fff;
            font-weight: bold;
            margin-right: 1rem;
        }
    }


    &__tooltip-toggle {
        width: 1.8rem;
        height: 1.8rem;
        border: none;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bolder;
        cursor: pointer;
        outline: none;
        color: var(--app-theme);
        border: 1px solid var(--app-theme);
        margin-left: 0.5rem;
        transform: translateY(1px);
    }
}

