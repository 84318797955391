.lens-proposal-builder-footer {
    grid-row: 4 / -1;
    grid-column: 1 / -1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 6rem;

    &__id,
    &__experiences {
        display: flex;
        align-items: center;

        img {
            margin-left: 2rem;
            cursor: pointer;
        }
    }

    &__experiences {
        position: relative;
        z-index: 6;
    }

    label {
        font-size: 1.4rem;
        font-weight: bold;
    }

    &__push-data {
        margin-left: 1.5rem;
    }

    &__saved {
        color: $theme;

        height: 100%;
        flex-flow: column;
        @extend .flex-center;

        h2 {
            margin-top: 2rem;
        }
    }
}


.lens-proposal-builder-footer{
    &--sun {
        grid-row: 3 / -1;
    }
}