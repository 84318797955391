@import './lens-consultation-brand';
@import './lens-consultation-brand-sun';
@import './lens-consultation-type';
@import './lens-consultation-product';
@import './lens-consultation-side';
@import './sub-products-dropdown';
@import './protect-color-picker';



.lens-consultation {
    display: flex;

    &__slide-container {
        flex:1;
        height:100%;
        position: relative;
        overflow: hidden;
    }

    &__slide-back-button {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;
        opacity: 0;
        transition: .25s ease-in-out;

        &--activate {
            pointer-events: initial;
            transition: 1s ease-in;

            opacity: 1;
        }
    }
}