.prescription-sunwear {
  display: flex;
  flex-flow: column;

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    > h2 {
      color: var(--app-sun-theme);
    }
  }

  &__subtitle {
    margin-top: 20px;
    font-size: 1.8rem;
    font-weight: 600;
    color: $text-color-light;
  }

  &__details {
    margin-top: 24px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    column-gap: 12px;
    position:relative;

    &-images{
      position: absolute;
      right: 5vw;
      top:0;
      display: flex;
      flex-direction: column;
      gap: 16px;
      img{
        width: 200px;
        border: 1px solid var(--app-theme-bg-light);
        border-radius: 5px;
      }
    }
  }

  &__details > .detail {
    font-size: 1.4rem;
    color: $text-color-light;
    padding: 8px;
    
    span{
      font-weight: 600;
      font-size: 1.7rem;
      max-width: 60vw;
    }

    > ul {
      list-style: inside;
      max-width: 60vw;
      
    }
  }

  &__footer {
    margin-top: 16px;
    font-size: 1.7rem;
    font-weight: 600;
    color: $text-color-light;
    max-width: 60vw;

    &-brands{
      margin-top: 24px;
      display: flex;
      justify-content: center;
      gap: 32px;
      img{
        width: 200px;
        cursor: pointer;
      }
    }
  }
}
